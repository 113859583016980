import React, { FC, useMemo } from 'react'

import styled from 'styled-components'
import { Skeleton } from 'antd'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  CampaignTypeEnum,
} from 'src/Types'
import { TrackInfo } from 'src/Components/TrackInfo'
import { RelaunchButton } from 'src/Components/Buttons/RelaunchButton'
import { UpsellButton } from 'src/Components/Buttons/UpsellButton'
import { FIXED_BY } from 'src/Constants/constants'

import { BudgetSection } from '../BudgetSection'

const SummaryWrapper = styled.div`
  display: flex;
  width: 320px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    width: 100%;
    gap: 48px;
  }
`

const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    border-radius: 16px;
  }
  &.ant-skeleton {
    flex-grow: 1 !important;
    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      width: 100% !important;
    }
  }
  & > .ant-skeleton-input {
    width: 100% !important;
    height: 240px !important;
    border-radius: 16px !important;
  }
`

interface SummarySectionProps {
  loading: boolean
  campaignStatus?: CampaignStatusEnum
  budget?: string
  spent?: string
  refund?: string
  trackCoverUrl?: string
  trackName?: string
  trackAuthorName?: string
  startDate?: string
  endDate?: string
  genres?: Array<string>
  isTiktokPayed?: boolean
  onUpsellCampaign: () => void
  onRelaunchCampaign: () => void
  campaignType?: CampaignTypeEnum
  trackActiveCampaignsCount?: number
}

export const SummarySection: FC<SummarySectionProps> = ({
  campaignStatus,
  budget,
  loading,
  trackCoverUrl,
  trackName,
  refund,
  trackAuthorName,
  startDate,
  endDate,
  genres,
  spent,
  isTiktokPayed,
  onUpsellCampaign,
  onRelaunchCampaign,
  campaignType,
  trackActiveCampaignsCount,
}) => {
  const showCreateTiktokButton =
    !isTiktokPayed && campaignStatus === CampaignStatusEnum.ENDED

  const showRelaunchButton = trackActiveCampaignsCount === 0

  const relaunchBtnFlag =
    campaignStatus &&
    campaignType !== CampaignTypeEnum.RELAUNCHED &&
    [
      CampaignStatusEnum.STOPPED,
      CampaignStatusEnum.ENDED,
      CampaignStatusEnum.STOPPED_BY_ADMIN,
    ].includes(campaignStatus)

  const button = useMemo(() => {
    if (relaunchBtnFlag && !showCreateTiktokButton && showRelaunchButton) {
      return <RelaunchButton onClick={onRelaunchCampaign} />
    }
    if (showCreateTiktokButton) {
      return (
        <UpsellButton
          onClick={onUpsellCampaign}
          platform={CampaignPlatformTypeEnum.TIKTOK}
        />
      )
    }
    return null
  }, [
    relaunchBtnFlag,
    showCreateTiktokButton,
    showRelaunchButton,
    onRelaunchCampaign,
    onUpsellCampaign,
  ])

  if (loading) {
    return <StyledSkeleton />
  }

  if (!campaignStatus) {
    return null
  }
  const budgetNumber = isNaN(Number(budget)) ? 0 : Number(budget)
  const refundNumber = isNaN(Number(refund)) ? 0 : Number(refund)

  const spendString = refundNumber
    ? (budgetNumber - refundNumber).toFixed(FIXED_BY)
    : spent

  return (
    <SummaryWrapper>
      <TrackInfo
        platform={CampaignPlatformTypeEnum.SPOTIFY}
        status={campaignStatus}
        trackImage={trackCoverUrl}
        trackName={trackName}
        artistName={trackAuthorName}
        startDate={startDate}
        endDate={endDate}
        genres={genres}
      >
        {button}
      </TrackInfo>
      <BudgetSection
        campaignStatus={campaignStatus}
        budget={budget}
        spent={spendString}
      />
    </SummaryWrapper>
  )
}
