import { FC } from 'react'

import { Modal } from 'antd'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { MAX_TABLET_WIDTH, MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import Bell from 'src/Assets/Svg/header-icons/bell.svg?react'
import { UserNotification } from 'src/Types'
import { NewReleaseResponse } from 'src/Redux/newReleaseApi'
import {
  NOTIFICATIONS_WIDTH_DESKTOP,
  NOTIFICATIONS_WIDTH_MOBILE,
} from 'src/Constants/constants'
import { NotificationButton } from 'src/Components/Buttons/NotificationButton'

import { TopBarNotificationContent } from '../TopBarNotificationContent'

const RelativeWrapper = styled.div`
  position: relative;
`

const StyledModal = styled(Modal)`
  margin-right: revert !important;
  .ant-modal-content {
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.common.primaryBorder};
    box-shadow: 0px 0px 12px 0px rgba(51, 51, 51, 0.12);
    padding: 24px 0px 24px 24px;
  }
  .ant-modal-close {
    top: 19px;
    right: 19px;
  }
`

const StyledTitle = styled.div`
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
  margin-bottom: 24px;
`

export interface HeaderNotificationsProps {
  isHeaderNotificationsPopupVisible: boolean
  onNotificationsButtonClick: () => void
  handleCreateAndStartSchedule?: () => void
  hasNewNotifications: boolean
  notifications: UserNotification[]
  releaseData: NewReleaseResponse | undefined
}

export const HeaderNotifications: FC<HeaderNotificationsProps> = ({
  onNotificationsButtonClick,
  isHeaderNotificationsPopupVisible,
  handleCreateAndStartSchedule,
  hasNewNotifications,
  notifications,
  releaseData,
}) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })
  const isDesktop = useMediaQuery({ minWidth: MAX_TABLET_WIDTH })

  return (
    <RelativeWrapper>
      <NotificationButton
        hasNewNotifications={hasNewNotifications}
        onClick={onNotificationsButtonClick}
      >
        <Bell />
      </NotificationButton>
      <StyledModal
        title={<StyledTitle>{t('notification.notifications')}</StyledTitle>}
        style={{ top: 8, right: 8 }}
        centered={false}
        open={isHeaderNotificationsPopupVisible}
        keyboard={true}
        footer={null}
        mask={!isDesktop}
        width={
          isMobile ? NOTIFICATIONS_WIDTH_MOBILE : NOTIFICATIONS_WIDTH_DESKTOP
        }
        onOk={onNotificationsButtonClick}
        onCancel={onNotificationsButtonClick}
        onClose={onNotificationsButtonClick}
      >
        <TopBarNotificationContent
          handleCreateAndStartSchedule={handleCreateAndStartSchedule}
          notifications={notifications}
          releaseData={releaseData}
        />
      </StyledModal>
    </RelativeWrapper>
  )
}
