import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TikTokTrack } from 'src/Types'
import { setApiError } from 'src/Hooks/redux'

import { NameSpace, SliceState, SliceStatus } from '../types'

import { searchTikTokTrackNoAuth, searchTikTokTrackAuth } from './api-actions'

export interface ExistingTikTokCampaign {
  id: number
  trackTitle: string
  trackCoverUrl: string
  trackAuthorName: string
}

export interface TikTokTrackState extends SliceState {
  tikTokTrack: TikTokTrack | null
  tikTokTracks: Array<TikTokTrack> | null
  existingTikTokCampaign: ExistingTikTokCampaign | null
}

const initialState: TikTokTrackState = {
  tikTokTrack: null,
  status: SliceStatus.Idle,
  tikTokTracks: null,
  error: null,
  existingTikTokCampaign: null,
}

export const tikTokTrackProcess = createSlice({
  initialState,
  name: NameSpace.Artists,
  reducers: {
    setTiktokTrackToInitialState(state) {
      state.tikTokTrack = null
      state.error = null
      state.status = SliceStatus.Idle
    },

    setTiktokTracksToInitialState(state) {
      state.tikTokTracks = null
      state.error = null
      state.status = SliceStatus.Idle
    },
    setTikTokTrackFromSearchList(state, action: PayloadAction<TikTokTrack>) {
      state.tikTokTrack = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchTikTokTrackAuth.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(searchTikTokTrackAuth.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
      state.tikTokTracks = null
      state.existingTikTokCampaign =
        action.payload?.data?.tiktokCampaignData || null
    })
    builder.addCase(searchTikTokTrackAuth.fulfilled, (state, action) => {
      state.tikTokTracks = action.payload || null
      state.error = null
      state.status = SliceStatus.Succeeded
    })

    builder.addCase(searchTikTokTrackNoAuth.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(searchTikTokTrackNoAuth.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
      state.tikTokTrack = null
    })
    builder.addCase(searchTikTokTrackNoAuth.fulfilled, (state, action) => {
      state.tikTokTracks = action.payload
      state.error = null
      state.status = SliceStatus.Succeeded
    })
  },
})

export const {
  setTiktokTrackToInitialState,
  setTiktokTracksToInitialState,
  setTikTokTrackFromSearchList,
} = tikTokTrackProcess.actions
