import { FC } from 'react'

import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import TikTokIcon from 'src/Assets/Svg/upsell/TikTok.svg?react'
import RunningIcon from 'src/Assets/Svg/upsell/Running.svg?react'

import {
  CampaignUpsellButtonContent,
  CampaignUpsellButtonsContainer,
  CampaignUpsellContainer,
  CampaignUpsellTextContainer,
  CampaignUpsellTitle,
} from './styles'

import { CampaignUpsellProps } from '.'

const Container = styled(CampaignUpsellContainer)`
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

const Text = styled(CampaignUpsellTitle)`
  font-size: 14px;
  font-weight: 400;
`

const Accent = styled.span`
  color: ${({ theme }) => theme.button.primaryText};
`
const Button = styled(PrimaryButton)`
  min-width: 280px;
`
const ButtonContent = styled(CampaignUpsellButtonContent)`
  color: ${({ theme }) => theme.button.primaryText};
  svg path {
    fill: ${({ theme }) => theme.button.primaryText};
  }
`

type Props = Pick<CampaignUpsellProps, 'onRelaunch' | 'onUpsellTiktok'>

export const CampaignUpsellExcellent: FC<Props> = ({
  onRelaunch,
  onUpsellTiktok,
}) => (
  <Container>
    <CampaignUpsellTextContainer>
      <CampaignUpsellTitle>Great work!</CampaignUpsellTitle>
      <Text>
        Your campaign outperformed <Accent>60%</Accent> of new users. Keep up
        the momentum to reach even greater heights.
      </Text>
    </CampaignUpsellTextContainer>
    <CampaignUpsellButtonsContainer>
      <Button onClick={onRelaunch}>
        <ButtonContent>
          <RunningIcon />
          <div>Relaunch to Reach New Curators</div>
        </ButtonContent>
      </Button>
      <Button onClick={onUpsellTiktok}>
        <ButtonContent>
          <TikTokIcon />
          <div>Boost Your Reach on TikTok</div>
        </ButtonContent>
      </Button>
    </CampaignUpsellButtonsContainer>
  </Container>
)
