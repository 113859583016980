import styled from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

export const StyledCheckbox = styled.div`
  .ant-checkbox-wrapper {
    display: flex;
  }

  .ant-checkbox-wrapper:hover {
    .ant-checkbox-inner {
      border: 1px solid ${colors.greyTextBlackBg};
    }
    .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
      border: 1px solid ${colors.greyTextBlackBg};
      background-color: ${colors.white};
    }
  }

  .ant-checkbox .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    background-color: ${colors.white};
    border: 1px solid ${colors.greyTextBlackBg};
    border-radius: 4px;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: ${colors.green} !important;
    top: 45%;
  }

  .ant-checkbox-checked::after {
    border: transparent !important;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    background-color: ${colors.white};
    border: 1px solid ${colors.greyTextBlackBg};
    border-radius: 4px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 16px;
    height: 16px;
    background-color: ${colors.white};
    border: 1px solid ${colors.greyTextBlackBg};
    border-radius: 4px;
  }
`
export const Label = styled.span`
  font-size: ${variables.fontSize14};
  line-height: 21px;
  color: ${colors.mainText};
`
