import { FC, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BellIcon from 'mdi-react/BellIcon'
import { Popover } from 'antd'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { markCuratorNotificationsAsRead } from 'src/Redux/notification-process/api-actions'
import { getNotifications } from 'src/Redux/notification-process/selectors'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { notificationClickEvent } from 'src/Helpers/TagManager'
import { Roles } from 'src/Constants/enums'
import { useGetNewReleaseQuery } from 'src/Redux/newReleaseApi'
import { useCreateAndStartScheduleNewRelease } from 'src/Hooks/useCreateAndStartScheduleNewRelease'

import { TopBarNotificationContent } from './TopbarNotificationContent'

import {
  GlobalStyled,
  RelativeWrapper,
  Title,
  NotificationsTitleWrapper,
  TopBarButton,
  TopBarNewLabel,
  CloseButton,
} from './styles'

export const MARKER_TEST_ID = 'new-marker'

const TopBarNotification: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userId = useAppSelector(getUserId)
  const isArtist = useSelector(getIsArtist)
  const notifications = useSelector(getNotifications)
  const [visible, setVisible] = useState(false)

  const { data } = useGetNewReleaseQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: !isArtist,
  })

  const hasNewNotifications = notifications.some(({ isNew }) => isNew) || data

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.position = 'static'
    }
  }, [visible])

  const toggle = (): void => {
    setVisible(!visible)

    if (hasNewNotifications) {
      const markAction = markCuratorNotificationsAsRead

      void dispatch(markAction())
    }
  }

  const handleToggleAndSendEvent = (): void => {
    if (!visible) {
      notificationClickEvent({
        userId,
        accountType: isArtist ? Roles.artist : Roles.curator,
      })
    }
    toggle()
  }

  const { createAndStartSchedule } = useCreateAndStartScheduleNewRelease()

  const handleCreateAndStartSchedule = (): void => {
    createAndStartSchedule(true)
    setVisible(false)
  }

  return (
    <RelativeWrapper>
      <GlobalStyled />
      <Popover
        overlayClassName='notifications'
        placement='bottom'
        open={visible}
        content={
          <TopBarNotificationContent
            handleCreateAndStartSchedule={handleCreateAndStartSchedule}
          />
        }
        title={
          <NotificationsTitleWrapper>
            <Title>{t('notification.notifications')}</Title>

            <CloseButton onClick={toggle}>
              <CloseCross height={10} />
            </CloseButton>
          </NotificationsTitleWrapper>
        }
        trigger='click'
        onOpenChange={toggle}
      >
        <TopBarButton
          type='button'
          data-testid={'sidebar-button'}
          onClick={handleToggleAndSendEvent}
        >
          <BellIcon />
          {hasNewNotifications && (
            <TopBarNewLabel data-testid={MARKER_TEST_ID}>
              <div />
            </TopBarNewLabel>
          )}
        </TopBarButton>
      </Popover>
    </RelativeWrapper>
  )
}

export default TopBarNotification
