import { FC } from 'react'

import styled from 'styled-components'
import { Skeleton } from 'antd'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  CampaignTypeEnum,
} from 'src/Types'
import { TrackInfo } from 'src/Components/TrackInfo'

import { BudgetSection } from '../BudgetSection'

const SummaryWrapper = styled.div`
  display: flex;
  width: 320px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    width: 100%;
    gap: 48px;
  }
`

const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    border-radius: 16px;
  }
  &.ant-skeleton {
    flex-grow: 1 !important;
    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      width: 100% !important;
    }
  }
  & > .ant-skeleton-input {
    width: 100% !important;
    height: 240px !important;
    border-radius: 16px !important;
  }
`

interface SummarySectionProps {
  loading: boolean
  campaignStatus?: CampaignStatusEnum
  budget?: string
  spent?: number
  trackCoverUrl?: string
  trackName?: string
  trackAuthorName?: string
  startDate?: string
  endDate?: string
  genres?: Array<string>
  reserved?: number
  campaignType?: CampaignTypeEnum
  credits?: number
}

export const SummarySection: FC<SummarySectionProps> = ({
  campaignStatus,
  budget = 0.0,
  spent = 0.0,
  loading,
  trackCoverUrl,
  trackName,
  trackAuthorName,
  startDate,
  endDate,
  reserved,
  credits,
}) => {
  if (loading) {
    return <StyledSkeleton />
  }

  if (!campaignStatus) {
    return null
  }

  return (
    <SummaryWrapper>
      <TrackInfo
        platform={CampaignPlatformTypeEnum.TIKTOK}
        status={campaignStatus}
        trackImage={trackCoverUrl}
        trackName={trackName}
        artistName={trackAuthorName}
        startDate={startDate}
        endDate={endDate}
      />
      <BudgetSection
        campaignStatus={campaignStatus}
        budget={Number(budget)}
        spent={spent || 0}
        reserved={reserved}
        credits={credits}
      />
    </SummaryWrapper>
  )
}
