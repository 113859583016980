import { FunctionComponent } from 'react'

import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { APP } from 'src/Configs/App'
import { CampaignsListPage } from 'src/Pages/Artist/CampaignsListPage'
import { SpotifyCampaignDetailsPage } from 'src/Pages/Artist/SpotifyCampaignDetailsPage'
import { SpotifySchedulePage } from 'src/Pages/Artist/SpotifySchedulePage'
import { TiktokSchedulePage } from 'src/Pages/Artist/TiktokSchedulePage'
import { TiktokCampaignDetailsPage } from 'src/Pages/Artist/TiktokCampaignDetailsPage'
import { TikTokVideoDetailsPage } from 'src/Pages/Artist/TikTokVideoDetailsPage'
import { ArtistBalancePage } from 'src/Pages/Artist/ArtistBalancePage'
import { Subscriptions } from 'src/Pages/Artist/SubscriptionsPage'
import { PaySubscription } from 'src/Pages/Artist/SubscriptionsPage/PaySubscription/PaySubscription'
import { SuccessPaymentSubscription } from 'src/Pages/Artist/SubscriptionsPage/SuccessPaymentSubscription'
import { CancelSubscription } from 'src/Pages/Artist/SubscriptionsPage/CancelSubscription'
import { ReferAndEarnPage } from 'src/Pages/Artist/ReferAndEarnPage'
import { SettingsPage } from 'src/Pages/Artist/SettingsPage'
import { AdminPage } from 'src/Pages/Artist/AdminPage'
import { getUserEmail } from 'src/Redux/auth-process/userSlice/selectors'
import { ExpiredLinkPage } from 'src/Pages/Artist/ExpiredLinkPage'
import { ChoosePlatformAuthPage } from 'src/Pages/Artist/CreateCampaignPage/AuthPages/ChoosePlatformAuthPage'
import { SearchTrackAuth } from 'src/Pages/Artist/CreateCampaignPage/AuthPages/SearchTrackAuth'
import { Survey } from 'src/Pages/Artist/Survey'
import { SuccessCreatedFirst } from 'src/Pages/Artist/SuccessCreatedFirst'
import { SelectedTrackAuth } from 'src/Pages/Artist/CreateCampaignPage/AuthPages/SelectedTrackAuth'

export enum ArtistRoutesEnum {
  Root = '/',
  ChooseCreateCampaignPlatform = '/campaigns/new',
  ChooseCreateCampaignPlatformSpotify = '/campaigns/new/spotify',
  ChooseCreateCampaignPlatformTikTok = '/campaigns/new/tiktok',
  CampaignsList = '/campaigns',
  TiktokDetails = '/campaigns/details',
  SpotifyDetails = '/campaigns/view',
  SpotifySchedule = '/campaigns/schedule',
  TiktokSchedule = '/campaigns/scheduleTikTok',
  New = '/new',
  Settings = '/settings',
  ReferAndEarn = '/refer-and-earn',
  Balance = '/balance',
  Subscription = '/subscription',
  SubscriptionPayment = '/subscription/pay',
  SubscriptionPaymentSuccess = '/subscription/pay/success',
  SubscriptionCancel = '/subscription/cancel',
  Admin = '/admin/management',
  Errors = '/errors',
  Create = '/create',
  CreateSpotify = '/create/spotify',
  CreateTikTok = '/create/tiktok',
  CreatedFirst = '/created-first',
  Feedback = '/feedback',
  SuccessCreated = '/created-first/:campaignType',
  ReferralLinkNotExist = '/referral-link-not-exist',
}

export const ArtistComponentsNewUI: FunctionComponent = () => {
  const email = useSelector(getUserEmail)

  return (
    <Routes>
      <Route
        path={`${ArtistRoutesEnum.ChooseCreateCampaignPlatform}/:platform/:trackId`}
        Component={SelectedTrackAuth}
      />
      <Route
        path={`${ArtistRoutesEnum.Create}/:platform/:trackId`}
        Component={SelectedTrackAuth}
      />
      <Route
        path={`${ArtistRoutesEnum.ChooseCreateCampaignPlatform}/:platform`}
        Component={SearchTrackAuth}
      />
      <Route
        Component={ChoosePlatformAuthPage}
        path={`${ArtistRoutesEnum.ChooseCreateCampaignPlatform}`}
      />
      <Route
        path={ArtistRoutesEnum.SuccessCreated}
        Component={SuccessCreatedFirst}
      />
      <Route Component={ExpiredLinkPage} path={ArtistRoutesEnum.Errors} />

      {APP.admin_email.includes(String(email)) && (
        <Route Component={AdminPage} path={ArtistRoutesEnum.Admin} />
      )}
      <Route Component={SettingsPage} path={ArtistRoutesEnum.Settings}>
        <Route path=':page' Component={SettingsPage} />
      </Route>

      <Route
        Component={ReferAndEarnPage}
        path={ArtistRoutesEnum.ReferAndEarn}
      />

      <Route Component={ArtistBalancePage} path={ArtistRoutesEnum.Balance} />
      <Route Component={Subscriptions} path={ArtistRoutesEnum.Subscription} />
      <Route
        path={ArtistRoutesEnum.SubscriptionPayment}
        Component={PaySubscription}
      />
      <Route
        path={ArtistRoutesEnum.SubscriptionPaymentSuccess}
        Component={SuccessPaymentSubscription}
      />
      <Route
        path={ArtistRoutesEnum.SubscriptionCancel}
        Component={CancelSubscription}
      />

      <Route
        Component={TiktokSchedulePage}
        path={`${ArtistRoutesEnum.TiktokSchedule}/:number`}
      />
      <Route
        Component={SpotifySchedulePage}
        path={`${ArtistRoutesEnum.SpotifySchedule}/:number`}
      />
      <Route
        Component={SpotifyCampaignDetailsPage}
        path={`${ArtistRoutesEnum.SpotifyDetails}/:campaignID`}
      />

      <Route path={`${ArtistRoutesEnum.TiktokDetails}`}>
        <Route path=':campaignID' Component={TiktokCampaignDetailsPage} />
        <Route path=':campaignID/:videoID' Component={TikTokVideoDetailsPage} />
      </Route>

      <Route Component={CampaignsListPage} path='*' />
      <Route Component={CampaignsListPage} path={ArtistRoutesEnum.Root} />
      <Route
        Component={CampaignsListPage}
        path={ArtistRoutesEnum.CampaignsList}
      />
      <Route
        path={`${ArtistRoutesEnum.CreatedFirst}/:campaignType${ArtistRoutesEnum.Feedback}`}
        Component={Survey}
      />
    </Routes>
  )
}
