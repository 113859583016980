import React, { FC } from 'react'

import styled from 'styled-components'
import { Control, Controller } from 'react-hook-form'
import OutsideClickHandler from 'react-outside-click-handler'
import { useMediaQuery } from 'react-responsive'

import { PrimaryInput } from 'src/Components/PrimaryInput'
import { TrackAudioPlayer } from 'src/Components/TrackAudioPlayer'
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { Loading } from '../Loading'
import { TrackItem } from '../TrackItem'
import { SearchTrackFormData, TrackSearchProps } from '../../index'

const ResultsContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: 480px;
  overflow-y: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  position: absolute;
  top: 60px;
  z-index: 1;
`

const Results = styled.div`
  display: grid;
  gap: 4px;
  width: 100%;
  overflow-y: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  &::-webkit-scrollbar {
    width: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    height: 20px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 40px;
    background-color: ${({ theme }) => theme.common.thirdBorder};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.common.thirdBorder};
    width: 8px !important;
  }

  scrollbar-color: ${({ theme }) => theme.common.thirdBorder} transparent;

  & {
    -ms-overflow-style: none;
  }

  &::-ms-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-ms-track {
    background-color: transparent;
  }

  &::-ms-thumb {
    border-radius: 40px;
    background-color: ${({ theme }) => theme.common.thirdBorder};
  }
`

const InputContainer = styled.div`
  width: 100%;
  position: relative;
`

const ErrorWrapper = styled.div`
  padding: 12px;
  margin: 0 auto;
  width: calc(100% - 24px);

  @media (max-width: ${MAX_TABLET_WIDTH}) {
    width: calc(100% - 65px);
  }
`

type DesktopTabletTrackSearchProps = Pick<
  TrackSearchProps,
  | 'isLoading'
  | 'onItemClick'
  | 'onPlayClick'
  | 'tracks'
  | 'onSearch'
  | 'error'
  | 'defaultSearchValue'
  | 'onOutsideClick'
> & {
  placeholder: string
  control: Control<SearchTrackFormData>
}

export const DesktopTabletTrackSearch: FC<DesktopTabletTrackSearchProps> = ({
  isLoading,
  onItemClick,
  onPlayClick,
  tracks = [],
  onSearch,
  error,
  defaultSearchValue,
  onOutsideClick,
  placeholder,
  control,
}) => {
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })

  if (isMobile) {
    return null
  }

  return (
    <InputContainer>
      <Controller
        name='searchInput'
        control={control}
        defaultValue={defaultSearchValue || ''}
        render={({ field: { onChange, onBlur, value } }) => (
          <PrimaryInput
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              onSearch(e.target.value)
            }
          />
        )}
      />

      {isLoading && (
        <ResultsContainer>
          <Results>
            <Loading />
          </Results>
        </ResultsContainer>
      )}

      {tracks.length && !error ? (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
          <ResultsContainer>
            <Results>
              <TrackAudioPlayer
                render={({ loading, isPlaying, onClick, currentTrackUrl }) => (
                  <>
                    {tracks.map((item) => (
                      <TrackItem
                        key={item.id}
                        onItemClick={() => onItemClick(item.id)}
                        trackName={item.name}
                        artistName={item.artistName}
                        trackDuration={item.trackDuration}
                        trackCoverUrl={item.trackCoverUrl}
                        loading={loading && currentTrackUrl === item.previewUrl}
                        onTrackControlClick={(e) => {
                          onClick(e, item.previewUrl)
                          if (!isPlaying) {
                            onPlayClick && onPlayClick()
                          }
                        }}
                        isPlaying={
                          isPlaying && currentTrackUrl === item.previewUrl
                        }
                        show={Boolean(item.previewUrl)}
                      />
                    ))}
                  </>
                )}
              />
            </Results>
          </ResultsContainer>
        </OutsideClickHandler>
      ) : null}

      {error && (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
          <ResultsContainer>
            <Results>
              <ErrorWrapper>{error}</ErrorWrapper>
            </Results>
          </ResultsContainer>
        </OutsideClickHandler>
      )}
    </InputContainer>
  )
}
