import { FC, ReactNode } from 'react'

import styled from 'styled-components'

import Menu from 'src/Assets/Svg/header-icons/menu.svg?react'
import { IconButton } from 'src/Components/Buttons/IconButton'
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'
import { UserNotification } from 'src/Types'
import { NewReleaseResponse } from 'src/Redux/newReleaseApi'

import { HeaderMenu } from '../HeaderMenu'
import { BalanceButton } from '../BalanceButton'

import { HeaderNotifications } from './components/HeaderNotifications'

export const Container = styled.div<{
  withShadow: boolean
}>`
  display: flex;
  padding: 16px 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.common.primaryBg};

  box-shadow: ${({ withShadow }) =>
    withShadow ? '0px 0px 12px 0px rgba(51, 51, 51, 0.12)' : 'none'};
  transition: box-shadow 0.3s ease-in-out;

  @media (max-width: ${MAX_TABLET_WIDTH}) {
    padding: 16px 24px;
  }

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 16px;
  }
`

export const MenuButton = styled(IconButton)`
  @media (min-width: ${MAX_MOBILE_WIDTH}) {
    display: none;
  }

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    display: flex;
  }
`

interface MenuItem {
  title: string
  Icon: FC
  onClick: () => void
}

export interface PrimaryHeaderProps {
  withShadow: boolean
  children?: ReactNode
  onSidebarToggleClick: () => void
  onBalanceButtonClick: () => void
  balance: number
  isBalanceButtonLoading: boolean
  menuItems: Array<MenuItem>
  className?: string
  isHeaderNotificationsPopupVisible: boolean
  onNotificationsButtonClick: () => void
  hasNewNotifications: boolean
  handleCreateAndStartSchedule?: () => void
  notifications: UserNotification[]
  releaseData?: NewReleaseResponse
}

export const PrimaryHeader: FC<PrimaryHeaderProps> = ({
  withShadow,
  balance = 0,
  isBalanceButtonLoading,
  children,
  onSidebarToggleClick,
  onBalanceButtonClick,
  menuItems,
  className,
  isHeaderNotificationsPopupVisible,
  onNotificationsButtonClick,
  handleCreateAndStartSchedule,
  hasNewNotifications,
  notifications,
  releaseData,
}) => (
  <Container withShadow={withShadow} className={className}>
    <MenuButton onClick={onSidebarToggleClick}>
      <Menu />
    </MenuButton>
    {children}
    <BalanceButton
      balance={balance}
      onClick={onBalanceButtonClick}
      loading={isBalanceButtonLoading}
    />
    <HeaderNotifications
      onNotificationsButtonClick={onNotificationsButtonClick}
      isHeaderNotificationsPopupVisible={isHeaderNotificationsPopupVisible}
      handleCreateAndStartSchedule={handleCreateAndStartSchedule}
      hasNewNotifications={hasNewNotifications}
      notifications={notifications}
      releaseData={releaseData}
    />

    <HeaderMenu items={menuItems} />
  </Container>
)
