import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { TikTokTrack } from 'src/Types'
import { api } from 'src/Services/api'
import { APP } from 'src/Configs/App'
import { API_TIMEOUT_15000 } from 'src/Constants/constants'

import { ErrorCode, NameSpace } from '../types'

import { getTiktokTrackErrorMessage, TikTokTrackSearchErrorEnum } from './utils'

interface SearchTikTokTrackByNameBody {
  searchText: string
}

type SearchTikTokTrackResponse = Array<TikTokTrack>

enum SearchTikTokTrackFilterEnum {
  ALL = 0,
  TITLE = 1,
  CREATOR = 2,
}

interface ErrorResponseDataAuth {
  message?: string
  errorMessage?: string
  tiktokCampaignId?: string
}

export const searchTikTokTrackAuth = createAsyncThunk<
  SearchTikTokTrackResponse,
  SearchTikTokTrackByNameBody
>(
  `${NameSpace.TikTokTrack}/searchTikTokTrackByName`,
  async ({ searchText }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<SearchTikTokTrackResponse>(
        `${APP.TIKTOK_SERVER}/artist/tiktok-search/tracks`,
        {
          params: {
            search: searchText,
            filterBy: SearchTikTokTrackFilterEnum.ALL,
            offset: 0,
            limit: 20,
          },
          timeout: API_TIMEOUT_15000,
        },
      )

      if (data?.length === 0) {
        throw new Error(TikTokTrackSearchErrorEnum.NoTracksWereFound)
      }

      if (data[0].isOriginalSound) {
        throw new Error(TikTokTrackSearchErrorEnum.NotOfficialTrackError)
      }

      return data
    } catch (error) {
      const { message, response } = error as AxiosError<ErrorResponseDataAuth>

      return rejectWithValue({
        message:
          searchText &&
          getTiktokTrackErrorMessage({
            thrownErrorMessage: message,
            code: response?.status,
            responseErrorMessage:
              response?.data?.message || response?.data?.errorMessage,
          }),
        code: response?.status || ErrorCode.InternalServerError,
        data: response?.data,
      })
    }
  },
)

interface GetTikTokTrackWithoutAuthBody {
  searchText: string
}

interface ErrorResponseDataNoAuth {
  message: string
}

export const searchTikTokTrackNoAuth = createAsyncThunk<
  SearchTikTokTrackResponse,
  GetTikTokTrackWithoutAuthBody
>(
  `${NameSpace.TikTokTrack}/getTikTokTrackWithoutAuth`,
  async ({ searchText }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<SearchTikTokTrackResponse>(
        `${APP.TIKTOK_SERVER}/public/tiktok-search/tracks`,
        {
          params: {
            search: searchText,
            filterBy: SearchTikTokTrackFilterEnum.ALL,
            offset: 0,
            limit: 20,
          },
        },
      )

      if (data[0].isOriginalSound) {
        throw new Error(TikTokTrackSearchErrorEnum.NotOfficialTrackError)
      }
      return data
    } catch (error) {
      const { message, response } = error as AxiosError<ErrorResponseDataNoAuth>
      return rejectWithValue({
        message: getTiktokTrackErrorMessage({
          thrownErrorMessage: message,
          code: response?.status,
          responseErrorMessage: response?.data?.message,
        }),

        code: response?.status || ErrorCode.InternalServerError,
      })
    }
  },
)
