import { FC } from 'react'

import styled from 'styled-components'

import { Roles } from 'src/Constants/enums'

import { Avatar } from '../Avatar'

import { SidebarUserInfo } from './Components/SidebarUserInfo'
import { SidebarSubscriptionPlan } from './Components/SidebarSubscriptionPlan'
import { SidebarCuratorValidation } from './Components/SidebarCuratorValidation.tsx'

const Wrapper = styled.div<{ $hide: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ $hide }) => ($hide ? '50px' : '240px')};
  height: ${({ $hide }) => ($hide ? '200px' : 'auto')};
  gap: 16px;
  transform: ${({ $hide }) => ($hide ? 'translateX(-240px)' : 'translateX(0)')};
  transition: transform 1s ease-in-out;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

interface SidebarUserInfoProps {
  hide: boolean
  role: Roles
  firstName?: string
  userCover?: string
  lastName?: string
  planName?: string
  curatorVerified?: boolean | null
}

export const SidebarUserInfoContainer: FC<SidebarUserInfoProps> = ({
  hide,
  role,
  firstName,
  userCover,
  lastName,
  planName,
  curatorVerified,
}) => {
  const showValidation =
    typeof curatorVerified === 'boolean' ? curatorVerified : true
  return (
    <Wrapper $hide={hide}>
      <Avatar src={userCover} />
      <Container>
        <SidebarUserInfo
          role={role}
          firstName={firstName ?? ''}
          lastName={lastName ?? ''}
        />
        {planName && <SidebarSubscriptionPlan planName={planName} />}
        {!showValidation && <SidebarCuratorValidation />}
      </Container>
    </Wrapper>
  )
}
