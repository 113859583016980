import React, { FC } from 'react'

import styled from 'styled-components'
import ReactLoading from 'react-loading'

import PauseIcon from 'src/Assets/Svg/player/pause.svg?react'
import PlayIcon from 'src/Assets/Svg/player/play.svg?react'
import TrackCoverDefault from 'src/Assets/Svg/common/track-cover.svg'
import { SmartImage } from 'src/Components/SmartImage'

const POSITION_DIVIDER = 3

const IconWrapper = styled.div<{
  $size: number
}>`
  position: absolute;

  top: ${({ $size }) => $size / POSITION_DIVIDER}px;
  left: ${({ $size }) => $size / POSITION_DIVIDER}px;
`

const TrackImageWrapper = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`

export const TrackCoverImage = styled(SmartImage)<{
  $width: number
  $height: number
}>`
  display: flex;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  align-items: center;
`

const Loader = styled(ReactLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface PrimaryTrackCoverProps {
  trackCoverUrl: string
  loading: boolean
  onTrackControlClick?: (
    e: React.MouseEvent<HTMLDivElement>,
    trackUrl?: string,
  ) => void
  isPlaying: boolean
  show: boolean
  coverSize?: number
  iconSize?: number
}

const DEFAULT_COVER_SIZE = 48
const DEFAULT_ICON_SIZE = 16

export const PrimaryTrackCover: FC<PrimaryTrackCoverProps> = ({
  trackCoverUrl,
  loading,
  onTrackControlClick,
  isPlaying,
  show = true,
  coverSize = DEFAULT_COVER_SIZE,
  iconSize = DEFAULT_ICON_SIZE,
}) => {
  if (show) {
    return (
      <TrackImageWrapper onClick={onTrackControlClick}>
        <IconWrapper $size={coverSize}>
          {loading && <Loader type='spin' width={'30px'} height={'30px'} />}
          {isPlaying ? (
            <PauseIcon width={iconSize} height={iconSize} />
          ) : (
            <PlayIcon width={iconSize} height={iconSize} />
          )}
        </IconWrapper>

        <TrackCoverImage
          src={trackCoverUrl}
          fallbackSrc={TrackCoverDefault}
          $width={coverSize}
          $height={coverSize}
        />
      </TrackImageWrapper>
    )
  }

  return (
    <TrackImageWrapper onClick={onTrackControlClick}>
      <TrackCoverImage
        src={trackCoverUrl}
        fallbackSrc={TrackCoverDefault}
        $width={coverSize}
        $height={coverSize}
      />
    </TrackImageWrapper>
  )
}
