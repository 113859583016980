import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import LockIcon from 'src/Assets/Svg/pages-icons/Lock.svg?react'
import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { ScheduleSummaryProps } from '..'

import { ScheduleSummaryButtonBonusTooltip } from './ScheduleSummaryButtonBonusTooltip'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    gap: 0px;
  }
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    display: none;
  }
`
const Text = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 48px;
  padding-right: 48px;
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    padding: 8px 16px;
    justify-content: space-between;
    background: ${({ theme }) => theme.common.primaryBg};
    border-top: 1px solid ${({ theme }) => theme.common.primaryBorder};
  }
`
const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    display: none;
  }
`
const TotalTitle = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const Total = styled(TotalTitle)`
  font-size: 14px;
  font-weight: 600;
`

type Props = Pick<
  ScheduleSummaryProps,
  'onConfirm' | 'showTooltip' | 'countdown' | 'total' | 'paymentLoading'
>
export const ScheduleSummaryButton: FC<Props> = ({
  onConfirm,
  showTooltip,
  countdown,
  paymentLoading,
  total,
}) => {
  const { t } = useTranslation()

  const enoughBalance = total?.includes('0.00')

  const buttonText = enoughBalance
    ? t('scheduleSummary.launch')
    : t('scheduleSummary.confirm')

  return (
    <Container>
      {showTooltip && (
        <ScheduleSummaryButtonBonusTooltip countdown={countdown} />
      )}
      <ButtonWrapper>
        <TotalWrapper>
          <TotalTitle>{t('scheduleSummary.total')}</TotalTitle>
          <Total>{total}</Total>
        </TotalWrapper>
        <StyledPrimaryButton loading={paymentLoading} onClick={onConfirm}>
          {buttonText}
        </StyledPrimaryButton>
      </ButtonWrapper>
      {!enoughBalance && (
        <Wrapper>
          <LockIcon />
          <Text>{t('scheduleSummary.ssl')}</Text>
        </Wrapper>
      )}
    </Container>
  )
}
