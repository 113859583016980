import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { UserNotification } from 'src/Types'
import { toReversed } from 'src/Constants/functions'

export const curatorNotificationsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCuratorNotifications: builder.query<UserNotification[], void>({
      query: () => `${APP.SERVER}/curator/new-playlist-extra-scores`,
      transformResponse: (response: UserNotification[] = []) =>
        toReversed(response),
      providesTags: [{ type: 'CuratorNotifications', id: 'LIST' }],
    }),

    markCuratorNotificationsAsRead: builder.mutation<boolean, void>({
      query: () => ({
        url: `${APP.SERVER}/curator/new-playlist-extra-scores`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'CuratorNotifications', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetCuratorNotificationsQuery,
  useMarkCuratorNotificationsAsReadMutation,
} = curatorNotificationsApi
