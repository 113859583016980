import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { PrimaryHint } from 'src/Components/PrimaryHint'

import { ValidationText } from './ValidationText'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

const Title = styled.div`
  text-align: start;
  width: 100%;
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const List = styled.div``
const ListItem = styled(Title)`
  font-weight: 400;
`

export const SidebarCuratorValidation: FC = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <PrimaryHint placement='left' Component={ValidationText}>
        <Content>
          <Title>{t('sidebar.accountValidationPeriod')}</Title>
          <List>
            <ListItem>
              &#8226;&nbsp;{t('sidebar.whenYouJustRegistered')}
            </ListItem>

            <ListItem>
              &#8226;&nbsp;{t('sidebar.yourAccountNeedToBeValidated')}
            </ListItem>

            <ListItem>
              &#8226;&nbsp;{t('sidebar.youCanStillEarnExtraScores')}
            </ListItem>
          </List>
        </Content>
      </PrimaryHint>
    </Container>
  )
}
