import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

import { getArtistBalance } from 'src/Redux/auth-process/userSlice/selectors'
import {
  getTransactionsPayment,
  getTransactionsPaidOut,
} from 'src/Redux/transactions-process/selectors'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { RequestButton } from './RequestButton/RequestButton'

import {
  BalanceCard,
  CardsWrapper,
  BookingTotalTitle,
  BookingTotalDescription,
  RequestPayout,
  MobileRequestPayout,
} from './styles'

export const BalanceCards: FC = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH })
  const transactionsPayment = useSelector(getTransactionsPayment)
  const transactionsPaidOut = useSelector(getTransactionsPaidOut)
  const currentBalance = useSelector(getArtistBalance)

  return (
    <CardsWrapper>
      <BalanceCard>
        <BookingTotalDescription>
          {t('balancePage.totalEarned')}
        </BookingTotalDescription>
        <BookingTotalTitle>
          ${addSpaceToNumber(transactionsPayment, ',', true)}
        </BookingTotalTitle>
      </BalanceCard>
      <BalanceCard>
        <BookingTotalDescription>
          {t('balancePage.paidOut')}
        </BookingTotalDescription>
        <BookingTotalTitle>
          ${addSpaceToNumber(transactionsPaidOut, ',', true)}
        </BookingTotalTitle>
      </BalanceCard>
      <BalanceCard>
        <BookingTotalDescription>
          {t('balancePage.balance')}
        </BookingTotalDescription>
        <BookingTotalTitle green>
          ${addSpaceToNumber(Number(currentBalance), ',', true)}
        </BookingTotalTitle>
      </BalanceCard>
      {!isMobile && (
        <RequestPayout>
          <RequestButton />
        </RequestPayout>
      )}

      {isMobile && (
        <MobileRequestPayout>
          <RequestButton />
        </MobileRequestPayout>
      )}
    </CardsWrapper>
  )
}
