import { FC } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'

import InfinityLoader from '../InfinityLoader'

import { LoaderWrapper } from './styled'
import { InfinityScrollWrapperPropsType } from './types'

const InfinityScrollWrapper: FC<InfinityScrollWrapperPropsType> = ({
  children,
  dataLength,
  isLoading,
  next,
  hasMore,
}) => (
  <InfiniteScroll
    style={{ margin: '-16px', padding: '16px' }}
    dataLength={dataLength}
    next={next}
    hasMore={hasMore}
    loader={
      <LoaderWrapper>
        <InfinityLoader isLoading={isLoading} />
      </LoaderWrapper>
    }
  >
    {children}
  </InfiniteScroll>
)

export default InfinityScrollWrapper
