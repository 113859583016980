import { useMemo } from 'react'

import { useLazySearchTikTokTracksAuthQuery } from 'src/Redux/tiktokTrackSearchApi'
import { ExistingTikTokCampaign } from 'src/Redux/tiktokTrack-process'

interface UseSearchOneTrackAuth {
  searchTikTokTrack: ReturnType<typeof useLazySearchTikTokTracksAuthQuery>[0]
  tikTokSearchErrorMessage: string
  tikTokExistingCampaign: ExistingTikTokCampaign | null
}

export const useSearchOneTrackAuth = (): UseSearchOneTrackAuth => {
  const [searchTikTokTrack, { error: searchTikTokTrackError }] =
    useLazySearchTikTokTracksAuthQuery()

  const tikTokSearchErrorMessage = useMemo(
    () => (searchTikTokTrackError as { message?: string })?.message || '',
    [searchTikTokTrackError],
  )

  const tikTokExistingCampaign = useMemo(
    () =>
      (
        searchTikTokTrackError as {
          data?: { tiktokCampaignData?: ExistingTikTokCampaign }
        }
      )?.data?.tiktokCampaignData || null,
    [searchTikTokTrackError],
  )

  return {
    searchTikTokTrack,
    tikTokSearchErrorMessage,
    tikTokExistingCampaign,
  }
}
