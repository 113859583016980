import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { sounds } from 'src/Router/routes'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getIsBlockedCreatorSelector,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'
import { creatorReviewAvailableSongs } from 'src/Helpers/TagManager'

import { SoundListItemStatus } from '../SoundsListItemStatus'
import {
  MobileBlockWrapper,
  SoundsListItemContainer,
  StyledButton,
} from '../../styles'
import { TrackCover } from '../TrackCover'
import { EarnInfo } from '../EarnInfo'
import { TabKeysEnum } from '../../../../types'
import { SoundStatus } from '../../../../hooks/useSoundStatus'

interface NewSoundsListItemProps {
  campaignId: number
  trackCoverUrl: string
  trackTitle: string
  trackAuthorName: string
  trackTiktokId: string
  maxCreatorReward?: number
}

export const NewSoundsListItem: FunctionComponent<NewSoundsListItemProps> = ({
  campaignId,
  trackCoverUrl,
  trackTitle,
  trackAuthorName,
  trackTiktokId,
  maxCreatorReward,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const userId = useAppSelector(getUserId)
  const isCreatorBlocked = useAppSelector(getIsBlockedCreatorSelector)

  const onDetailsClick = (): void => {
    userId &&
      creatorReviewAvailableSongs({
        campaignId,
        userID: userId,
        reviewId: campaignId,
        trackId: trackTiktokId,
      })

    navigate(`${sounds}/${TabKeysEnum.NEW}/${campaignId}`)
  }
  return (
    <SoundsListItemContainer>
      <TrackCover
        trackAuthorName={trackAuthorName}
        trackCoverUrl={trackCoverUrl}
        trackTitle={trackTitle}
      />

      <EarnInfo maxCreatorReward={maxCreatorReward} />

      <SoundListItemStatus status={SoundStatus.RUNNING} />

      <MobileBlockWrapper>
        <StyledButton
          type={'green'}
          onClick={onDetailsClick}
          disabled={isCreatorBlocked}
        >
          {t('soundsPage.details')}
        </StyledButton>
      </MobileBlockWrapper>
    </SoundsListItemContainer>
  )
}
