import React, { FC } from 'react'

import styled from 'styled-components'

import { CampaignPlatformLabel } from 'src/Components/CampaignPlatformLabel'

import { ScheduleSummaryProps } from '..'

const Header = styled.div`
  display: flex;
  min-height: 70px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`
const TrackDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`

const Price = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: right;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const TrackInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`
const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

const TrackName = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

const TrackArtist = styled(TrackName)`
  font-weight: 400;
`

type Props = Pick<
  ScheduleSummaryProps,
  'artistName' | 'trackName' | 'platform' | 'price'
>

export const ScheduleSummaryDetailsHeader: FC<Props> = ({
  trackName,
  artistName,
  platform,
  price,
}) => (
  <Header>
    <TrackDetails>
      <TrackInfoContainer>
        <TrackInfo>
          <TrackName>{trackName}</TrackName>
          <TrackArtist>{artistName}</TrackArtist>
        </TrackInfo>
        <CampaignPlatformLabel platform={platform} />
      </TrackInfoContainer>
    </TrackDetails>
    <Price>{price}</Price>
  </Header>
)
