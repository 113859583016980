import React, { FC } from 'react'

import { CampaignUpsellNegative } from './CampaignUpsellNegative'
import { CampaignUpsellPositive } from './CampaignUpsellPositive'
import { CampaignUpsellExcellent } from './CampaignUpsellExcellent'

export type CampaignUpsellProps = {
  onUpsellTiktok: () => void
  onPromoteNewTrack: () => void
  onOpenIntercom: (message: string) => void
  onRelaunch: () => void
  placementsCount: number
}

export const CampaignUpsell: FC<CampaignUpsellProps> = (props) => {
  const {
    placementsCount,
    onUpsellTiktok,
    onPromoteNewTrack,
    onOpenIntercom,
    onRelaunch,
  } = props

  if (placementsCount === 0) {
    return <CampaignUpsellNegative onOpenIntercom={onOpenIntercom} />
  }

  if (placementsCount <= 2) {
    return (
      <CampaignUpsellPositive
        onUpsellTiktok={onUpsellTiktok}
        onPromoteNewTrack={onPromoteNewTrack}
      />
    )
  }
  return (
    <CampaignUpsellExcellent
      onRelaunch={onRelaunch}
      onUpsellTiktok={onUpsellTiktok}
    />
  )
}
