import { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'
import styled from 'styled-components'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { campaignsList, details } from 'src/Router/routes'
import { colors } from 'src/Styled/variables'
import { TikTokCampaignPricingModelEnum } from 'src/Types'
import { getValueOrZero } from 'src/Constants/functions'
import { useCampaignVideo } from 'src/Redux/campaignVideoApi'

import { RateContainer } from '../RateContainer'
import { TiktokReviewHeader } from '../TiktokReviewHeader'
import { TikTokPlayer } from '../TiktokPlayer'
import { CreatorInfo } from '../CreatorInfo'
import { TiktokRateError } from '../TiktokRateError'
import { ViewsProgressInfo } from '../ViewsProgressInfo'
import { WontChargedAlerts } from '../WontChargedAlerts'

import {
  CloseButton,
  TiktokDetailsContainer,
  TiktokReviewDetailsContainer,
  LoaderWrapper,
  TiktokReviewDesktopWrapper,
} from './styles'

export const InfoContainer = styled.div`
  display: flex;
  min-width: 288px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`

export const TiktokReviewDesktop: FC = () => {
  const navigate = useNavigate()
  const {
    video,
    isLoading,
    isVideoDeclinedOrRejected,
    isVideoDeleted,
    pricingModel,
    campaignID,
  } = useCampaignVideo()

  const handleClose = (): void => {
    if (campaignID) {
      navigate(`${campaignsList}${details}/${campaignID}`)
    }
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <ReactLoading
          color={colors.badgeGreen}
          width={'50px'}
          height={'50px'}
          type='spin'
        />
      </LoaderWrapper>
    )
  }

  const isViewsHidden =
    pricingModel === TikTokCampaignPricingModelEnum.FollowersBased ||
    isVideoDeclinedOrRejected ||
    isVideoDeleted

  return (
    <TiktokReviewDesktopWrapper>
      <TiktokDetailsContainer>
        <TiktokReviewHeader />
        <WontChargedAlerts />
        <TiktokRateError />
        <RateContainer />
        <TiktokReviewDetailsContainer>
          <TikTokPlayer url={video?.videoLink || ''} />
          <InfoContainer>
            <CreatorInfo />
            {!isViewsHidden && (
              <ViewsProgressInfo
                currentViews={getValueOrZero(video?.viewsCount)}
                minViews={getValueOrZero(video?.minViews)}
                milestoneDate={video?.minViewsDeadline || ''}
              />
            )}
          </InfoContainer>
        </TiktokReviewDetailsContainer>
      </TiktokDetailsContainer>
      <CloseButton onClick={handleClose}>
        <CloseCross width='16px' height='16px' />
      </CloseButton>
    </TiktokReviewDesktopWrapper>
  )
}
