import { FC } from 'react'

import { UserNotification } from 'src/Types'
import { NewReleaseResponse } from 'src/Redux/newReleaseApi'

import { TopBarNewRelease } from '../TopBarNewRelease'
import { NotificationsWrapper } from '../../styles'
import { HaveNotNotifications } from '../HaveNotNotifications'

import { NotificationItem } from './NotificationItem'

interface TopBarNotificationContentProps {
  handleCreateAndStartSchedule?: () => void
  notifications?: Array<UserNotification>
  releaseData?: NewReleaseResponse
}
export const TopBarNotificationContent: FC<TopBarNotificationContentProps> = ({
  handleCreateAndStartSchedule,
  notifications,
  releaseData,
}) => {
  if (!notifications?.length) {
    return (
      <>
        {releaseData && (
          <TopBarNewRelease
            handleCreateAndStartSchedule={handleCreateAndStartSchedule}
            releaseData={releaseData}
          />
        )}
        <HaveNotNotifications />
      </>
    )
  }

  return (
    <NotificationsWrapper>
      {notifications?.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          releaseData={releaseData}
          handleCreateAndStartSchedule={handleCreateAndStartSchedule}
        />
      ))}
    </NotificationsWrapper>
  )
}
