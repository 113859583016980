import React, { FC, useMemo } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import platform1 from 'src/Assets/Webp/TikTokErrorPlatform1.webp'
import platform2 from 'src/Assets/Webp/TikTokErrorPlatform2.webp'
import link2 from 'src/Assets/Webp/TikTokErrorLink2.webp'
import link1 from 'src/Assets/Webp/TikTokErrorLink1.webp'
import original2 from 'src/Assets/Webp/TikTokErrorOriginal2.webp'
import original1 from 'src/Assets/Webp/TikTokErrorOriginal1.webp'
import InfoIcon from 'src/Assets/Svg/common/info.svg?react'
import { variables } from 'src/Styled/variables'
import { CampaignPlatformTypeEnum, CampaignStatusEnum } from 'src/Types'
import { TikTokTrackSearchErrorEnum } from 'src/Redux/tiktokTrack-process/utils'
import { CampaignListItem } from 'src/Components/CampaignListItem'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'
import { ExistingTikTokCampaign } from 'src/Redux/tiktokTrack-process'

import { HowToGetTrackUrl } from '../TrackSearch/Components/HowToGetTrackUrl'
import { TrackSearchError } from '../TrackSearchError'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const StyledCampaignListItem = styled(CampaignListItem)`
  width: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

const Title = styled.span`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const ImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;

  img {
    width: 240px;
    height: auto;
  }
`

interface TikTokTrackSearchErrorProps {
  error: TikTokTrackSearchErrorEnum | string
  existingTikTokCampaign?: ExistingTikTokCampaign | null
}

export const TikTokTrackSearchError: FC<TikTokTrackSearchErrorProps> = ({
  error,
  existingTikTokCampaign,
}) => {
  const navigate = useNavigate()

  const errorData = useMemo(() => {
    if (error === TikTokTrackSearchErrorEnum.NotOfficialTrackError) {
      return {
        title: "The link you provided isn't the official one for this track.",
        images: [original1, original2],
      }
    }
    if (error === TikTokTrackSearchErrorEnum.UserLinkError) {
      return {
        title: 'It looks like this is a TikTok user link.',
        images: [link1, link2],
      }
    }
    if (error === TikTokTrackSearchErrorEnum.WrongPlatformError) {
      return {
        title: "You've added a link from a different service.",
        images: [platform1, platform2],
      }
    }

    if (error === TikTokTrackSearchErrorEnum.WrongFirstCharacter) {
      return 'Oops! It seems like you entered @nickname. To find the right track, just enter the track name into the search bar.'
    }

    if (error === TikTokTrackSearchErrorEnum.DefaultError) {
      return 'Oops! Something went wrong. Please try again later'
    }

    return error
  }, [error])

  if (!errorData || !error) {
    return null
  }

  const onStartCampaignClick = (): void => {
    navigate(`${ArtistRoutesEnum.TiktokSchedule}/${existingTikTokCampaign?.id}`)
  }

  const onDetailsClick = (): void => {
    navigate(`${ArtistRoutesEnum.TiktokDetails}/${existingTikTokCampaign?.id}`)
  }

  if (error === TikTokTrackSearchErrorEnum.ExistingCampaignTrack) {
    return (
      <StyledCampaignListItem
        status={CampaignStatusEnum.APPROVED}
        platform={CampaignPlatformTypeEnum.TIKTOK}
        trackName={existingTikTokCampaign?.trackTitle}
        artistName={existingTikTokCampaign?.trackAuthorName}
        trackImage={existingTikTokCampaign?.trackCoverUrl}
        onStartCampaignClick={onStartCampaignClick}
        onDetailsClick={onDetailsClick}
      />
    )
  }

  if (typeof errorData === 'string') {
    return <TrackSearchError>{errorData}</TrackSearchError>
  }

  return (
    <Container>
      <TitleContainer>
        <InfoIcon />
        <Title>{errorData.title}</Title>
      </TitleContainer>

      <ImagesWrapper>
        {errorData.images.map((image) => (
          <img key={image} src={image} alt='error' />
        ))}
      </ImagesWrapper>

      <HowToGetTrackUrl platform={CampaignPlatformTypeEnum.TIKTOK} />
    </Container>
  )
}
