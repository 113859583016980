import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { MAX_MOBILE_WIDTH, variables } from 'src/Styled/variables'
import guideImage1 from 'src/Assets/Webp/step1.webp'
import guideImage2 from 'src/Assets/Webp/step2.webp'
import guideImage3 from 'src/Assets/Webp/step3.webp'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { PrimaryInfoModal } from 'src/Components/PrimaryInfoModal'

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  align-self: center;
  height: 48px;
`

const SubTitle = styled.span`
  font-family: 'Segoe UI';
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize12};
  font-style: normal;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const Text = styled.li`
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI';
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize12};
  font-style: normal;
  line-height: 150%;
  color: ${({ theme }) => theme.common.primaryText};
`

const ImageWrapper = styled.div`
  width: 172px;
  height: 356px;
  border-radius: 16px;
  overflow: hidden;
`

const ItemInfoWrapper = styled.div`
  display: flex;
  max-width: 172px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

const GuideItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

const GuideItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  padding: 24px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`

interface HowToGetTrackUrlProps {
  platform: CampaignPlatformTypeEnum
}

const guideItems = [
  {
    title: 'Go to the Discovery Tab',
    steps: [
      'Open TikTok on your phone (this cannot be done on a desktop)',
      'Tap the Discovery tab (compass icon)',
    ],
    image: guideImage1,
  },
  {
    title: 'Search for Your Track',
    steps: [
      'Type the track and artist name in the search bar',
      'Go to the Sounds tab',
      'Tap on name of your track to open its page',
    ],
    image: guideImage2,
  },
  {
    title: 'Copy the Link',
    steps: [
      'Tap the Share button (arrow icon)',
      'Select Copy link',
      'Paste this link into Soundcamp’s search field',
    ],
    image: guideImage3,
  },
]

export const HowToGetTrackUrl: FC<HowToGetTrackUrlProps> = ({ platform }) => {
  const { t } = useTranslation()
  const { setSearch, search } = useCustomSearchParams()

  const isHowGetTrackModalVisible =
    search.get('isHowGetTrackModalVisible') === 'true'

  const isTikTok = platform === CampaignPlatformTypeEnum.TIKTOK

  if (!isTikTok) {
    return null
  }

  const onOpen = (): void => {
    setSearch(
      {
        isHowGetTrackModalVisible: 'true',
      },
      { replace: true },
    )
  }

  const onClose = (): void => {
    setSearch(
      {
        isHowGetTrackModalVisible: null,
      },
      { replace: true },
    )
  }

  return (
    <>
      <PrimaryInfoModal
        isOpen={isHowGetTrackModalVisible}
        title={t('howToGetYourTrackURL.howToGetYourTrackURL')}
        onClose={onClose}
      >
        <GuideItemsContainer>
          {guideItems.map((item, index) => (
            <GuideItemContainer key={item.title}>
              <ItemInfoWrapper>
                <SubTitle>{item.title}</SubTitle>
                <ul>
                  {item.steps.map((step) => (
                    <Text key={step}>{step}</Text>
                  ))}
                </ul>
              </ItemInfoWrapper>
              <ImageWrapper>
                <img src={item.image} alt={`Guide image ${index}`} />
              </ImageWrapper>
            </GuideItemContainer>
          ))}
        </GuideItemsContainer>
      </PrimaryInfoModal>
      <StyledPrimaryDottedButton onClick={onOpen}>
        {t('howToGetYourTrackURL.howToGetYourTrackURL')}
      </StyledPrimaryDottedButton>
    </>
  )
}
