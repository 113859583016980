import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { locationEvent, pageViewEvent } from 'src/Helpers/TagManager'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { pageInfo } from 'src/Helpers/TagManager/pageInfo'
import { useAppSelector } from 'src/Hooks/redux'

import Balance from './components/Balance'

const Container = styled.div`
  display: flex;
  max-width: 1040px;
  padding: 24px 0px;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

/**
 * Balance page. Curator's side.
 */

const CuratorBalancePage: React.FunctionComponent = () => {
  const { t } = useTranslation()

  const userID = useAppSelector(getUserId)

  useEffect(() => {
    if (userID) {
      pageViewEvent({ userID, currentPageInfo: pageInfo.balance })
      locationEvent()
    }
  }, [userID])

  return (
    <Container>
      <Title>{t('balancePage.pageTitle')}</Title>
      <Balance />
    </Container>
  )
}

export default CuratorBalancePage
