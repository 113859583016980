import { FC } from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/Hooks/redux'
import {
  getBonusPerCampaign,
  getInvitedArtists,
  getTotalBonus,
} from 'src/Redux/referral-process/referralList/selectors'
import { BonusBlock } from 'src/Components/BonusBlock'

const Wrapper = styled.div`
  margin: 0 -16px;
`

const InternalWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  gap: 16px;
  @media (max-width: 1023px) {
    overflow-x: scroll;
    flex-direction: row-reverse;
  }
  width: 100%;
  padding: 16px;
  border-radius: 16px;
`

export const ReferAndEarnBonusBlocks: FC = () => {
  const { t } = useTranslation()
  const total = useAppSelector(getTotalBonus)
  const bonusPerCampaign = useAppSelector(getBonusPerCampaign)
  const invitedArtists = useAppSelector(getInvitedArtists)

  return (
    <Wrapper>
      <InternalWrapper>
        <BonusBlock title={t('referAndEarn.totalBonus')} value={`$${total}`} />
        <BonusBlock
          title={t('referAndEarn.totalArtistsInvited')}
          value={invitedArtists}
        />
        <BonusBlock
          title={t('referAndEarn.bonusPerCampaign')}
          value={bonusPerCampaign}
        />
      </InternalWrapper>
    </Wrapper>
  )
}
