import { FC, ReactNode } from 'react'

import styled from 'styled-components'

import { CampaignStatusEnum } from 'src/Types'
import PlayIcon from 'src/Assets/Svg/campaign-statuses-icons/Play.svg?react'
import RunningIcon from 'src/Assets/Svg/campaign-statuses-icons/Running.svg?react'
import StopIcon from 'src/Assets/Svg/campaign-statuses-icons/Stop.svg?react'
import SuccessIcon from 'src/Assets/Svg/campaign-statuses-icons/Success.svg?react'
import TimeIcon from 'src/Assets/Svg/campaign-statuses-icons/Time.svg?react'
import i18n from 'src/Localization/i18n'

const Container = styled.div`
  display: flex;
  width: 240px;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  color: ${({ theme }) => theme.common.primaryText};
  text-overflow: ellipsis;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
export interface CampaignStatusProps {
  status: CampaignStatusEnum
  className?: string
}

const STOPPED = i18n.t('campaignsPage.stopped')

const statusByIconMap: Partial<Record<CampaignStatusEnum, ReactNode>> = {
  [CampaignStatusEnum.APPROVED]: <PlayIcon />,
  [CampaignStatusEnum.PENDING]: <PlayIcon />,
  [CampaignStatusEnum.PENDING_APPROVAL]: <TimeIcon />,
  [CampaignStatusEnum.AWAITING_REVIEW]: <TimeIcon />,
  [CampaignStatusEnum.STOPPED]: <StopIcon />,
  [CampaignStatusEnum.ENDED]: <SuccessIcon />,
  [CampaignStatusEnum.RUNNING]: <RunningIcon />,
  [CampaignStatusEnum.SCHEDULED]: <TimeIcon />,
  [CampaignStatusEnum.CANCELED_BY_ADMIN]: <StopIcon />,
  [CampaignStatusEnum.STOPPED_BY_ADMIN]: <StopIcon />,
}

const statusMap: Partial<Record<CampaignStatusEnum, string>> = {
  [CampaignStatusEnum.STOPPED]: STOPPED,
  [CampaignStatusEnum.ENDED]: i18n.t('campaignsPage.ended'),
  [CampaignStatusEnum.RUNNING]: i18n.t('campaignsPage.running'),
  [CampaignStatusEnum.SCHEDULED]: i18n.t('campaignsPage.scheduled'),
  [CampaignStatusEnum.APPROVED]: i18n.t('campaignsPage.ApprovedToStart'),
  [CampaignStatusEnum.PENDING]: i18n.t('campaignsPage.ApprovedToStart'),
  [CampaignStatusEnum.PENDING_APPROVAL]: i18n.t(
    'campaignsPage.pendingApproval',
  ),
  [CampaignStatusEnum.AWAITING_REVIEW]: i18n.t('campaignsPage.pendingApproval'),
  [CampaignStatusEnum.CANCELED_BY_ADMIN]: STOPPED,
  [CampaignStatusEnum.STOPPED_BY_ADMIN]: STOPPED,
}

export const CampaignStatus: FC<CampaignStatusProps> = (props) => {
  const { status, className } = props
  if (status in statusMap) {
    return (
      <Container className={className}>
        {statusByIconMap[status]}
        <span>{statusMap[status]}</span>
      </Container>
    )
  }
  return null
}
