import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { StatsBlock } from 'src/Components/StatsSectionBlock'
import { CampaignPlatformTypeEnum, CampaignStatusEnum } from 'src/Types'

interface StatsBlockContainerProps {
  reviewsCount?: number
  placementsCount?: number
  campaignStatus?: CampaignStatusEnum
}

export const StatsBlockContainer: FC<StatsBlockContainerProps> = ({
  reviewsCount,
  placementsCount,
  campaignStatus,
}) => {
  const { t } = useTranslation()

  if (!campaignStatus) {
    return null
  }

  const sections = [
    {
      title: t('campaignDetailsPage.reviews'),
      value: reviewsCount,
    },
    {
      title: t('campaignDetailsPage.placements'),
      value: placementsCount,
    },
  ]

  const isStatsBlockHidden =
    [
      CampaignStatusEnum.PENDING_APPROVAL,
      CampaignStatusEnum.APPROVED,
      CampaignStatusEnum.SCHEDULED,
    ].includes(campaignStatus) ||
    (campaignStatus === CampaignStatusEnum.RUNNING && reviewsCount === 0)

  if (isStatsBlockHidden) {
    return null
  }

  return (
    <StatsBlock
      sections={sections}
      platform={CampaignPlatformTypeEnum.SPOTIFY}
    />
  )
}
