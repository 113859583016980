import React from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Avatar } from 'src/Components/Avatar'
import { ShareButton } from 'src/Components/Buttons/ShareButton'
import { variables } from 'src/Styled/variables'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

import { AddedPlaylistInfo } from '../AddedPlaylistInfo'
import { MatchedPlaylists } from '../MatchedPlaylistsInfo'

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  background: ${({ theme }) => theme.common.primaryBg};
`

export const CuratorInfoBlock = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const CuratorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`

export const AccentText = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight600};
  line-height: 150%;
`

export const CommonText = styled.span`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize14};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  word-break: break-word;
`

export const PlacementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`

export interface ReviewPlaylist {
  playlistName: string
  playlistUrl?: string
}

export interface ReviewBlockProps {
  userCover: string
  firstName: string
  lastName: string
  playlistsCount: number
  followersCount: number
  comment: string
  addedTrackPlaylist?: ReviewPlaylist
  matchedTrackPlaylists: Array<ReviewPlaylist>
  onShareClick: () => void
}

export const ReviewBlock: React.FC<ReviewBlockProps> = ({
  userCover,
  firstName,
  lastName,
  playlistsCount,
  followersCount,
  comment,
  addedTrackPlaylist,
  matchedTrackPlaylists,
  onShareClick,
}) => {
  const { t } = useTranslation()

  const playlistsText = `${playlistsCount} ${t('campaignDetailsPage.playlists')}`
  const followersText = `${addSpaceToNumber(followersCount, ',')} ${t('campaignDetailsPage.followers')}`

  return (
    <Container>
      <CuratorInfoBlock>
        <Avatar width={40} height={40} src={userCover} />

        <CuratorInfo>
          <AccentText>{`${firstName} ${lastName}`}</AccentText>
          <CommonText>{`${playlistsText}, ${followersText}`}</CommonText>
        </CuratorInfo>

        <ShareButton onClick={onShareClick} data-testid='share-button' />
      </CuratorInfoBlock>
      <CommonText>{comment}</CommonText>

      {addedTrackPlaylist && (
        <AddedPlaylistInfo
          playlistName={addedTrackPlaylist.playlistName}
          playlistUrl={addedTrackPlaylist.playlistUrl}
        />
      )}
      {matchedTrackPlaylists && !addedTrackPlaylist && (
        <MatchedPlaylists matchedTrackPlaylists={matchedTrackPlaylists} />
      )}
    </Container>
  )
}
