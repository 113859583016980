import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { CampaignStatusEnum } from 'src/Types'
import { StatsBlock } from 'src/Components/StatsSectionBlock'
import { HORIZONTAL_AMOUNT } from 'src/Constants/constants'

interface BudgetSectionProps {
  campaignStatus: CampaignStatusEnum
  budget?: number | null
  spent?: number
  reserved?: number
  credits?: number
}

export const BudgetSection: FC<BudgetSectionProps> = ({
  campaignStatus,
  budget,
  spent,
  reserved,
  credits,
}) => {
  const { t } = useTranslation()

  const budgetSectionVisible = ![
    CampaignStatusEnum.AWAITING_REVIEW,
    CampaignStatusEnum.PENDING,
  ].includes(campaignStatus)

  if (!budgetSectionVisible) {
    return null
  }

  const isCreditsDisplayed = [
    CampaignStatusEnum.ENDED,
    CampaignStatusEnum.STOPPED,
    CampaignStatusEnum.CANCELED,
    CampaignStatusEnum.CANCELED_BY_ADMIN,
    CampaignStatusEnum.STOPPED_BY_ADMIN,
  ].includes(campaignStatus)

  const sections = [
    {
      title: t('campaignDetailsPage.budget'),
      value: `$${budget?.toFixed(2)}`,
    },
    {
      title: t('campaignDetailsPage.reserved'),
      value: `$${reserved?.toFixed(2)}`,
      isVisible: !isCreditsDisplayed,
    },
    {
      title: t('campaignDetailsPage.spent'),
      value: `$${spent?.toFixed(2)}`,
    },
    {
      title: t('campaignDetailsPage.credits'),
      value: `$${credits?.toFixed(2)}`,
      isVisible: isCreditsDisplayed && reserved === 0,
    },
  ].filter((section) => section.isVisible !== false)

  return (
    <StatsBlock
      sections={sections}
      $isHorizontal={sections.length <= HORIZONTAL_AMOUNT}
    />
  )
}
