import { FC } from 'react'

import styled from 'styled-components'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import {
  useCampaignVideo,
  useSetCampaignVideoRateMutation,
} from 'src/Redux/campaignVideoApi'
import dayjs from 'src/Helpers/dayjs'
import { useHandleRateEvents } from 'src/Containers/TiktokReview/components/RateContainer/RateForm/useHandleRateEvents'

import { RateBlock, RateFormData } from './Components/RateBlock'
import { VideoDetails } from './Components/VideoDetails'
import { TikTokPlayer } from './Components/TiktokPlayer'
import { TikTokVideoDetailsBreadcrumbs } from './Components/TikTokVideoDetailsBreadcrumbs'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 48px;
    padding-bottom: 56px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  max-width: 1120px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 48px;
    flex-direction: column;
    align-items: center;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 48px;
  }
`

const DAYS_BETWEEN_MIN_VIEWS_DEADLINE_AND_PUBLISH_DATE = 5
const HOURS_BETWEEN_REVIEWS_DEADLINE_AND_PUBLISH_DATE = 72

export const TikTokVideoDetailsPage: FC = () => {
  const { video, isLoading, pricingModel } = useCampaignVideo()
  const [onRateEvents] = useHandleRateEvents()
  const [setCampaignVideoRate, { isLoading: isRateLoading }] =
    useSetCampaignVideoRateMutation({
      fixedCacheKey: 'setCampaignVideoRate',
    })

  const onRateSubmit = (value: RateFormData): void => {
    if (video?.id) {
      setCampaignVideoRate({
        id: video?.id,
        feedback: value.comment,
        stars: value.rate,
      })
        .unwrap()
        .then((res) => {
          onRateEvents({
            data: res,
            videoID: video.id,
            feedback: value.comment,
            videoLink: video.videoLink,
          })
        })
    }
  }

  const videoPublishedDate = dayjs(video?.minViewsDeadline)
    .subtract(DAYS_BETWEEN_MIN_VIEWS_DEADLINE_AND_PUBLISH_DATE, 'day')
    .toString()

  const approvedAtDate = dayjs(video?.videoUploadDate || '')
    .add(HOURS_BETWEEN_REVIEWS_DEADLINE_AND_PUBLISH_DATE, 'hour')
    .toString()

  return (
    <PageContainer>
      <TikTokVideoDetailsBreadcrumbs />
      <ContentContainer>
        <TikTokPlayer url={video?.videoLink} />
        <InfoContainer>
          <VideoDetails
            userPhotoUrl={video?.userPhotoUrl}
            firstName={video?.nickname}
            followers={video?.followerCount}
            currentViews={video?.viewsCount}
            minViews={video?.minViews}
            videoStatus={video?.status}
            videoPublishedDate={videoPublishedDate}
            milestoneDate={video?.minViewsDeadline}
            dismissReason={video?.dismissReason}
            loading={isLoading}
            pricingModel={pricingModel}
            tiktokProfileUrl={video?.tiktokProfileUrl}
            videoPreModerationStatus={video?.preModerationStatus}
          />
          <RateBlock
            rateDeadline={approvedAtDate}
            onSubmit={onRateSubmit}
            rate={video?.stars}
            comment={video?.feedback}
            isRateLoading={isRateLoading}
            loading={isLoading}
          />
        </InfoContainer>
      </ContentContainer>
    </PageContainer>
  )
}
