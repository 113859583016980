import { API_TIMEOUT_15000 } from 'src/Constants/constants'
import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'

import { TiktokTrackResponse } from '../firstTrackApi'
import {
  getTiktokTrackErrorMessage,
  TikTokTrackSearchErrorEnum,
} from '../tiktokTrack-process/utils'
import { ErrorCode } from '../types'

interface SearchTikTokTrackByNameBody {
  searchText: string
}

type SearchTikTokTrackResponse = Array<TiktokTrackResponse>

enum SearchTikTokTrackFilterEnum {
  ALL = 0,
  TITLE = 1,
  CREATOR = 2,
}

const tiktokTrackSearchApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    searchTikTokTracksAuth: builder.query<
      SearchTikTokTrackResponse,
      SearchTikTokTrackByNameBody
    >({
      query: ({ searchText }) => ({
        url: `${APP.TIKTOK_SERVER}/artist/tiktok-search/tracks`,
        params: {
          search: searchText,
          filterBy: SearchTikTokTrackFilterEnum.ALL,
          offset: 0,
          limit: 20,
        },
        timeout: API_TIMEOUT_15000,
      }),
      transformErrorResponse(baseQueryReturnValue, _) {
        const data = baseQueryReturnValue.data as {
          errorMessage?: string
          statusCode?: number
          message?: string
        }

        return {
          message: getTiktokTrackErrorMessage({
            thrownErrorMessage: data.errorMessage,
            code: data.statusCode,
            responseErrorMessage: data?.message || data?.errorMessage,
          }),
          code: data.statusCode || ErrorCode.InternalServerError,
          data,
        }
      },
      transformResponse(trackResponse: SearchTikTokTrackResponse) {
        if (trackResponse?.length === 0) {
          throw new Error(TikTokTrackSearchErrorEnum.NoTracksWereFound)
        }

        if (trackResponse[0].isOriginalSound) {
          throw new Error(TikTokTrackSearchErrorEnum.NotOfficialTrackError)
        }
        return trackResponse
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useSearchTikTokTracksAuthQuery,
  useLazySearchTikTokTracksAuthQuery,
} = tiktokTrackSearchApi
