import { useEffect, useMemo } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { useSearchTikTokTracksAuthQuery } from 'src/Redux/tiktokTrackSearchApi'
import { getValueOrEmptyString } from 'src/Helpers/layoutHelpers'
import { convertTimeHandler, getValueOrZero } from 'src/Constants/functions'
import { usePageViewEvent } from 'src/Hooks/Analytics/useTrackEvents'
import { Track } from 'src/Redux/firstTrackApi'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getSpotifyToken } from 'src/Redux/spotifyToken-process/selectors'
import { NAVIGATE_BACK_INDEX } from 'src/Constants/constants'
import { useGetSpotifyTracksQuery } from 'src/Redux/spotifyTracksApi'
import { getSpotifyAccessToken } from 'src/Redux/spotifyToken-process/api-actions'

export interface FirstChosenTrackParams {
  [key: string]: string | undefined
  platform?: CampaignPlatformTypeEnum
  trackId?: string
}

const MILLISECONDS_PER_SECOND = 1000

export interface TikTokTrack extends Track {
  isOriginalSound?: boolean
  initialVideoCount?: number
  trackUrl?: string
}

export interface SpotifyTrack extends Track {
  artistSpotifyId?: string
}

export const useSelectedTrackAuth = (): {
  spotifyTrack: SpotifyTrack | null
  tikTokTrack: TikTokTrack | null
  loading: boolean
} => {
  const dispatch = useAppDispatch()
  const { trackAddedEventHandler } = usePageViewEvent()
  const accessToken = useAppSelector(getSpotifyToken)
  const navigate = useNavigate()
  const { platform, trackId } = useParams<FirstChosenTrackParams>()

  const {
    data: tiktokTracks,
    isLoading: isTiktokLoading,
    isError: isTiktokError,
  } = useSearchTikTokTracksAuthQuery(
    {
      searchText: `https://www.tiktok.com/music/-${trackId}`,
    },
    {
      skip:
        !trackId || !platform || platform !== CampaignPlatformTypeEnum.TIKTOK,
    },
  )
  const {
    data: spotifyTracks,
    isLoading: isSpotifyLoading,
    isError: isSpotifyError,
  } = useGetSpotifyTracksQuery(
    {
      search: `https://open.spotify.com/track/${trackId}`,
      token: accessToken || '',
    },
    {
      skip:
        !trackId ||
        !accessToken ||
        !platform ||
        platform !== CampaignPlatformTypeEnum.SPOTIFY,
    },
  )

  const spotifyTrack = useMemo(() => {
    if (platform === CampaignPlatformTypeEnum.SPOTIFY) {
      return {
        trackId: spotifyTracks?.[0]?.id,
        trackName: spotifyTracks?.[0]?.trackName,
        artistName: spotifyTracks?.[0]?.trackAuthor,
        trackCoverUrl: spotifyTracks?.[0]?.trackImage,
        trackDuration: convertTimeHandler({
          value: spotifyTracks?.[0]?.trackDuration || 0,
          type: 'ms',
        }),
        trackPreviewUrl: spotifyTracks?.[0]?.previewUrl,
        trackDurationInMs: spotifyTracks?.[0]?.trackDuration || 0,
        artistSpotifyId: spotifyTracks?.[0]?.artistSpotifyId,
      }
    }
    return null
  }, [platform, spotifyTracks])

  const tikTokTrack = useMemo(() => {
    if (platform === CampaignPlatformTypeEnum.TIKTOK) {
      return {
        trackId: tiktokTracks?.[0]?.trackTiktokId,
        trackName: tiktokTracks?.[0]?.trackTitle,
        artistName: tiktokTracks?.[0]?.trackAuthorName,
        trackCoverUrl: tiktokTracks?.[0]?.trackCoverUrl,
        trackDuration: convertTimeHandler({
          value: tiktokTracks?.[0].trackDuration || 0,
          type: 's',
        }),
        trackPreviewUrl: tiktokTracks?.[0]?.trackPlayUrl,
        trackDurationInMs:
          (tiktokTracks?.[0].trackDuration || 0) * MILLISECONDS_PER_SECOND,
        originalSound: tiktokTracks?.[0],
        initialVideoCount: tiktokTracks?.[0].initialVideoCount,
        trackUrl: tiktokTracks?.[0]?.trackUrl,
        isOriginalSound: tiktokTracks?.[0].isOriginalSound,
      }
    }
    return null
  }, [platform, tiktokTracks])

  useEffect(() => {
    const track =
      platform === CampaignPlatformTypeEnum.TIKTOK ? tikTokTrack : spotifyTrack

    if (track && platform) {
      trackAddedEventHandler({
        artistName: getValueOrEmptyString(track.artistName),
        platformType: platform,
        trackDuration: getValueOrZero(track.trackDurationInMs),
        trackId: getValueOrEmptyString(track.trackId),
        trackName: getValueOrEmptyString(track.trackName),
      })
    }
  }, [
    platform,
    spotifyTrack,
    tikTokTrack,
    tiktokTracks,
    trackAddedEventHandler,
  ])

  useEffect(() => {
    if (isSpotifyError || isTiktokError) {
      navigate(NAVIGATE_BACK_INDEX)
    }
  }, [isSpotifyError, isTiktokError, navigate])

  useEffect(() => {
    void dispatch(getSpotifyAccessToken(false))
  }, [dispatch])

  return {
    spotifyTrack,
    tikTokTrack,
    loading: isSpotifyLoading || isTiktokLoading,
  }
}
