import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { QuestionButton } from 'src/Components/QuestionButton'

const Text = styled.div`
  color: ${({ theme }) => theme.common.fifthText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 13px;
`

const StyledQuestionButton = styled(QuestionButton)`
  background-color: ${({ theme }) => theme.common.fifthBg};
  .QuestionIcon path {
    fill: ${({ theme }) => theme.common.primaryBg};
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.common.secondaryBg};
  }

  &:focus-visible {
    transform: none;
    border-radius: 40px;
    outline: 1px solid ${({ theme }) => theme.common.primaryDetails};
    background-color: ${({ theme }) => theme.common.fifthBg};
  }

  &:active:not(:disabled) {
    background-color: ${({ theme }) => theme.common.fifthBg};
    svg path {
      fill: ${({ theme }) => theme.common.secondaryText};
    }
  }
`

export const ValidationText: FC<{ onClick?: () => void }> = (props) => {
  const { t } = useTranslation()
  return (
    <Text {...props}>
      <div>{t('sidebar.accountOnValidation')}</div>
      <StyledQuestionButton />
    </Text>
  )
}
