import styled from 'styled-components'

export const CampaignUpsellContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.dangerBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
`
export const CampaignUpsellButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`
export const CampaignUpsellTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

export const CampaignUpsellTitle = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`

export const CampaignUpsellButtonContent = styled.div`
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`
