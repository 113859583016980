import { FC } from 'react'

import styled from 'styled-components'

import { colors } from 'src/Styled/variables'
import { useAppSelector } from 'src/Hooks/redux'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'

import { SubscriptionCashback } from './SubscriptionCashback'

const Wrapper = styled.div`
  display: flex;
  padding: 6px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid ${colors.greyStroke};
  background-color: ${colors.greyBg};
  justify-content: space-between;
  font-size: 14px;
`

interface ScheduleSubscriptionProps {
  price: number
  symbol: string
}

export const ScheduleSubscription: FC<ScheduleSubscriptionProps> = ({
  price,
  symbol,
}) => {
  const hasActivePlan = useAppSelector(
    getHasActiveOrCanceledSubscriptionSelector,
  )

  if (hasActivePlan) {
    return (
      <Wrapper>
        <SubscriptionCashback price={price} symbol={symbol} />
      </Wrapper>
    )
  }
  return null
}
