import { FC } from 'react'

import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import MessageIcon from 'src/Assets/Svg/upsell/Message.svg?react'
import AnalyticsIcon from 'src/Assets/Svg/upsell/Analysis.svg?react'

import {
  CampaignUpsellButtonContent,
  CampaignUpsellButtonsContainer,
  CampaignUpsellContainer,
  CampaignUpsellTextContainer,
  CampaignUpsellTitle,
} from './styles'

import { CampaignUpsellProps } from '.'

const Text = styled(CampaignUpsellTitle)`
  font-size: 14px;
  font-weight: 400;
`

const Button = styled(PrimaryButton)`
  min-width: 280px;
`
const ButtonContent = styled(CampaignUpsellButtonContent)`
  svg path {
    fill: ${({ theme }) => theme.common.primaryText};
  }
`

const BottomText = styled(Text)`
  margin-top: 21px;
`
const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`

const ListItem = styled.li`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-style: normal;
  line-height: 150%;
  font-size: 14px;
  font-weight: 400;
`

type Props = Pick<CampaignUpsellProps, 'onOpenIntercom'>

export const CampaignUpsellNegative: FC<Props> = ({ onOpenIntercom }) => (
  <CampaignUpsellContainer>
    <CampaignUpsellTextContainer>
      <CampaignUpsellTitle>Campaign summary</CampaignUpsellTitle>
      <div>
        <Text>
          Your track generated interest, but placements didn’t land this time.
          Here are some common factors:
        </Text>
        <List>
          <ListItem>Niche or specific genre</ListItem>
          <ListItem>Unique music style</ListItem>
          <ListItem>Production quality considerations</ListItem>
        </List>
        <BottomText>
          Let’s explore ways to enhance your next campaign!
        </BottomText>
      </div>
    </CampaignUpsellTextContainer>
    <CampaignUpsellButtonsContainer>
      <Button onClick={() => onOpenIntercom('Request Campaign Analysis')}>
        <ButtonContent>
          <AnalyticsIcon />
          <Text>Request Campaign Analysis</Text>
        </ButtonContent>
      </Button>
      <Button onClick={() => onOpenIntercom('Report a Concern')}>
        <ButtonContent>
          <MessageIcon />
          <Text>Report a Concern</Text>
        </ButtonContent>
      </Button>
    </CampaignUpsellButtonsContainer>
  </CampaignUpsellContainer>
)
