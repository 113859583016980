import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Roles } from 'src/Constants/enums'
import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'
import { User } from 'src/Types'

import {
  acceptTermsNew,
  activateCurator,
  getCuratorRank,
  getIntercomHash,
  getSpotifyAvatar,
  getUserInfo,
  setGcidToUser,
  setIsNewFeed,
  usersMe,
} from './api-actions'

export interface UserState {
  user: User
  referralCode: null | string
  isPassword: boolean
  isEmailBounced: boolean
  isBlocked: boolean
  verified: boolean | null
  is_new_term: boolean
  curator_id?: number | null
  role: Roles | null
  phone_number?: string | null
  error: ApiError | null
  status: SliceStatus
}

const initialState: UserState = {
  user: {
    isReferral: false,
    intercomHash: null,
    balance: 0,
    isBlocked: false,
    first_name: '',
    last_name: '',
    lastActivity: '',
    last_activity: '',
    ip: '',
    isArtist: false,
    is_email_confirmed: false,
    is_first_campaign: false,
    isEmailConfirmed: false,
    release_notes: null,
    score: 0,
    are_terms_accepted: null,
    createdAt: '',
    email: '',
    gcid: '',
    geo: {
      country: '',
    },
    country: '',
    id: '',
    phone_number: '',
    role: Roles.artist,
    terms_position: null,
    rank: 0,
    playlist_requirement: false,
    spotifyId: null,
    influencerId: 0,
  },
  referralCode: null,
  isPassword: false,
  isEmailBounced: false,
  isBlocked: false,
  verified: false,
  is_new_term: false,
  curator_id: null,
  role: null,
  phone_number: null,
  error: null,
  status: SliceStatus.Idle,
}

export const userSlice = createSlice({
  initialState,
  name: NameSpace.User,
  reducers: {
    resetUserBalance: (state) => {
      state.user.balance = 0
    },
    setIsFirstCampaign: (state, { payload }: PayloadAction<boolean>) => {
      state.user.is_first_campaign = payload
    },
    clearUserState: () => initialState,
  },
  extraReducers: (builder) => {
    // getUserInfo
    builder.addCase(getUserInfo.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      let firstName
      let lastName

      if (payload.curator) {
        firstName = payload.curator.first_name
        lastName = payload.curator.last_name
      } else {
        firstName = payload.first_name
        lastName = payload.last_name
      }
      state.status = SliceStatus.Succeeded
      state.isPassword = payload.isPassword
      state.isEmailBounced = payload.isEmailBounced
      state.verified = payload.curator ? payload.curator.verified : null
      state.phone_number = payload.phone_number
      state.curator_id = payload?.curator?.id
      state.referralCode = payload.referral_code
      state.user = {
        ...state.user,
        id: payload.user_id,
        email: payload.email,
        user_photo_url: payload.user_photo_url,
        balance: payload.balance,
        complaints: payload.complaints,
        isDeactivated: payload.isDeactivated,
        country: payload.country,
        first_name: firstName,
        createdAt: payload.created_at,
        last_name: lastName,
        are_terms_accepted: payload.curator
          ? payload.curator.are_terms_accepted
          : null,
        terms_position: payload.curator ? payload.curator.terms_position : null,
        spotifyId: payload.curator ? payload.curator.spotify_id : null,
        is_email_confirmed: payload.is_email_confirmed,
        curatorId: payload.curator ? payload.curator.id : null,
        is_new_feed: payload?.curator?.is_new_feed,
        is_new_term: payload.is_new_term,
        artist: {
          is_tiktok_allowed: payload?.artist?.is_tiktok_allowed,
          show_tiktok_alert: payload?.artist?.show_tiktok_alert,
          artist_role: payload?.artist?.artist_role,
        },
      }
    })
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
    // getCuratorRank
    builder.addCase(getCuratorRank.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getCuratorRank.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.user = {
        ...state.user,
        rank: payload.rank,
        score: payload.score,
      }
    })
    builder.addCase(getCuratorRank.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // setIsNewFeed
    builder.addCase(setIsNewFeed.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(setIsNewFeed.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
      state.user = {
        ...state.user,
        is_new_feed: false,
      }
    })
    builder.addCase(setIsNewFeed.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // acceptTermsNew
    builder.addCase(acceptTermsNew.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(acceptTermsNew.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.user = {
        ...state.user,
        terms_position: payload.terms_position,
        are_terms_accepted: payload.are_terms_accepted,
      }
    })
    builder.addCase(acceptTermsNew.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // activateCurator
    builder.addCase(activateCurator.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(activateCurator.fulfilled, (state) => {
      state.status = SliceStatus.Succeeded
      state.user = {
        ...state.user,
        isDeactivated: false,
      }
    })
    builder.addCase(activateCurator.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // setGcidToUser
    builder.addCase(setGcidToUser.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(setGcidToUser.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.user = {
        ...state.user,
        gcid: payload.gcid,
      }
    })
    builder.addCase(setGcidToUser.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // usersMe
    builder.addCase(usersMe.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(usersMe.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.role = payload.role
      state.user = {
        ...state.user,
        user_photo_url: payload.userPhotoUrl,
        influencerId: payload.metaData.id,
        first_name: payload.firstName,
        last_name: payload.lastName,
        createdAt: payload.createdAt,
        updatedAt: payload.updatedAt,
        email: payload.email,
        spotifyId: payload.spotifyId,
        isEmailConfirmed: payload.isEmailConfirmed,
        lastActivity: payload.lastActivity,
        id: payload.id,
        isBlocked: payload.metaData.isBlocked,
      }
    })
    builder.addCase(usersMe.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // getSpotifyAvatar
    builder.addCase(getSpotifyAvatar.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getSpotifyAvatar.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.user = {
        ...state.user,
        user_photo_url: payload.new_avatar_image,
      }
    })
    builder.addCase(getSpotifyAvatar.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // getIntercomHash, need to merge with getUserInfo
    builder.addCase(getIntercomHash.pending, (state) => {
      state.status = SliceStatus.Loading
    })
    builder.addCase(getIntercomHash.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.Succeeded
      state.user = {
        ...state.user,
        intercomHash: payload.intercomHash,
        isReferral: payload.isReferral,
      }
    })
    builder.addCase(getIntercomHash.rejected, (state, action) => {
      state.status = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})

export const { resetUserBalance, setIsFirstCampaign, clearUserState } =
  userSlice.actions
