import { FunctionComponent, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useCheckReferralCodeQuery } from 'src/Redux/signupReferalCodeApi'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'
import { PublicRoutesEnum } from 'src/Router/PublicComponentsNewUI'

const AlertAmount = styled.span`
  color: ${({ theme }) => theme.common.sixthText};
`

const StyledAlert = styled(PrimaryAlert)`
  max-width: 960px;
  margin: 0 auto;
`

export const WelcomeAlert: FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { search } = useCustomSearchParams()

  const referralCode = search?.get('referralCode')
  const { data, isError } = useCheckReferralCodeQuery(
    { referralCode },
    { skip: !referralCode },
  )

  useEffect(() => {
    if (referralCode && isError) {
      navigate(`${PublicRoutesEnum.ReferralNotExist}`)
    }
  }, [isError, navigate, referralCode])

  if (!referralCode && !data?.firstName) {
    return null
  }

  return (
    <StyledAlert
      type={'success'}
      title={t('referAndEarn.welcomeToSoundCampaign')}
      hasCross={false}
    >
      <div>
        {data?.firstName} &nbsp;
        {t('referAndEarn.hasInvitedYouToCreateAFirstCampaignForYourSong')}
        &nbsp;<AlertAmount>$30</AlertAmount>&nbsp;
        {t('referAndEarn.hasAlreadyBeenCreditedToYourBalance')}
      </div>
    </StyledAlert>
  )
}
