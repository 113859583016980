import { Roles } from 'src/Constants/enums'
import i18n from 'src/Localization/i18n'
import {
  subscription,
  campaignsList,
  referAndEarn,
  balance,
  admin,
} from 'src/Router/routes'
import Campaign from 'src/Assets/Svg/sidebar-icons/Campaign.svg?react'
import Money from 'src/Assets/Svg/sidebar-icons/Money.svg?react'
import Question from 'src/Assets/Svg/sidebar-icons/Question.svg?react'
import ReferAndEarn from 'src/Assets/Svg/sidebar-icons/ReferAndEarn.svg?react'
import Star from 'src/Assets/Svg/sidebar-icons/Star.svg?react'
import Playlists from 'src/Assets/Svg/sidebar-icons/Playlists.svg?react'
import Listening from 'src/Assets/Svg/sidebar-icons/Listening.svg?react'
import Rank from 'src/Assets/Svg/sidebar-icons/Rank.svg?react'
import IconAdminPanel from 'src/Assets/Svg/header-icons/settingsNew.svg?react'
import { CuratorsRouteEnum } from 'src/Router/CuratorComponentsNewUI'

import { SidebarMenuItemProps } from '../SidebarMenuItem'
const BALANCE = 'sidebar.balance'

type MenuItem = Omit<
  SidebarMenuItemProps,
  'children' | 'onClick' | 'collapsed' | 'active' | 'target'
> & {
  name: string
}

export class MenuItemsFactory {
  menuItems: MenuItem[] = []

  adminMenuItem = {
    name: 'Admin Panel',
    icon: IconAdminPanel,
    path: admin,
    isFeatured: false,
  }

  helpCenterMenuItem = {
    name: 'Help center',
    icon: Question,
    path: 'https://help.soundcamps.com/en/',
    isFeatured: false,
    target: '_blank',
  }
  balanceMenuItem = {
    name: i18n.t(BALANCE),
    icon: Money,
    path: balance,
    isFeatured: false,
  }
  artistMenuItems: MenuItem[] = [
    {
      name: i18n.t('sidebar.premium'),
      icon: Star,
      path: subscription,
      isFeatured: true,
    },
    {
      name: i18n.t('sidebar.campaigns'),
      icon: Campaign,
      path: campaignsList,
      isFeatured: false,
    },
    {
      name: i18n.t('sidebar.referAndEarn'),
      icon: ReferAndEarn,
      path: referAndEarn,
      isFeatured: false,
    },

    {
      name: i18n.t(BALANCE),
      icon: Money,
      path: balance,
      isFeatured: false,
    },
  ]

  curatorMenuItems: MenuItem[] = [
    {
      name: i18n.t('sidebar.tracksFeed'),
      icon: Listening,
      path: CuratorsRouteEnum.Tracks,
      isFeatured: false,
    },
    {
      name: i18n.t('sidebar.rank'),
      icon: Rank,
      path: CuratorsRouteEnum.Rank,
      isFeatured: false,
    },
    this.balanceMenuItem,
    {
      name: i18n.t('sidebar.playlists'),
      icon: Playlists,
      path: CuratorsRouteEnum.Playlists,
      isFeatured: false,
    },
    this.helpCenterMenuItem,
  ]

  isAdmin = false
  role: Roles = Roles.artist
  hasPlan = false
  constructor(role: Roles, hasPlan: boolean, isAdmin?: boolean) {
    this.role = role
    this.hasPlan = hasPlan
    this.isAdmin = Boolean(isAdmin)
    this.buildMenuItems()
  }
  public getItems(): MenuItem[] {
    return this.menuItems
  }

  private getArtistNavigationList(): MenuItem[] {
    return this.hasPlan
      ? this.getArtistNavigationListWithPlan()
      : this.artistMenuItems
  }

  private getArtistNavigationListWithPlan(): MenuItem[] {
    return this.artistMenuItems.filter((item) => item.path !== subscription)
  }

  private buildMenuItems(): void {
    switch (this.role) {
      case Roles.artist: {
        const items = this.getArtistNavigationList()
        this.menuItems = this.isAdmin
          ? [...items, this.adminMenuItem, this.helpCenterMenuItem]
          : [...items, this.helpCenterMenuItem]
        break
      }
      case Roles.curator:
        this.menuItems = this.curatorMenuItems
        break
      case Roles.influencer:
        this.menuItems = []
        break
      default:
        this.menuItems = []
    }
  }
}
