import { useMemo, useState } from 'react'

import { useSelector } from 'react-redux'

import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { Roles } from 'src/Constants/enums'
import { notificationClickEvent } from 'src/Helpers/TagManager'
import { UserNotification } from 'src/Types'
import {
  useGetCuratorNotificationsQuery,
  useMarkCuratorNotificationsAsReadMutation,
} from 'src/Redux/curatorNotificationsApi'

export const useCuratorNotifications = (): {
  sortedCuratorNotifications: UserNotification[]
  hasNewCuratorNotifications: boolean
  isHeaderNotificationsPopupVisible: boolean
  onNotificationsButtonClick: () => void
} => {
  const userId = useSelector(getUserId)

  const role = useSelector(getUserRole)

  const { data: curatorNotifications } = useGetCuratorNotificationsQuery()
  const [markCuratorNotificationsAsRead] =
    useMarkCuratorNotificationsAsReadMutation()

  const [
    isHeaderNotificationsPopupVisible,
    setHeaderNotificationsPopupVisible,
  ] = useState(false)

  const hasNewCuratorNotifications = Boolean(
    curatorNotifications?.some(({ isNew }) => isNew),
  )

  const sortedCuratorNotifications = useMemo(
    () =>
      [...(curatorNotifications || [])].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
      ),
    [curatorNotifications],
  )

  const onNotificationsButtonClick = (): void => {
    if (!isHeaderNotificationsPopupVisible) {
      notificationClickEvent({
        userId,
        accountType: role ?? Roles.artist,
      })
    }

    setHeaderNotificationsPopupVisible(!isHeaderNotificationsPopupVisible)

    if (isHeaderNotificationsPopupVisible && hasNewCuratorNotifications) {
      void markCuratorNotificationsAsRead()
    }
  }

  return {
    sortedCuratorNotifications,
    hasNewCuratorNotifications,
    isHeaderNotificationsPopupVisible,
    onNotificationsButtonClick,
  }
}
