import styled from 'styled-components'

import { colors, variables } from 'src/Styled/variables'

export const CardsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  margin: 0 -16px;
  @media (max-width: 1023px) {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    flex-direction: row-reverse;
  }
`

export const BookingTotalDescription = styled.h5`
  font-weight: ${variables.fontWeight400};
  font-size: ${variables.fontSize14};
  color: ${colors.mainText};
  margin-bottom: 16px;
  line-height: 21px;
`
interface Props {
  green?: boolean
}
export const BookingTotalTitle = styled.h5<Props>`
  font-weight: ${variables.fontWeight600};
  font-size: ${variables.fontSize20};
  color: ${({ green }) => (green ? colors.green : colors.mainText)};
  white-space: nowrap;
  line-height: 30px;
`
export const BalanceCard = styled.div`
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--Neutral-Light-Grey, #e7e7e7);
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  @media (max-width: 1023px) {
    min-width: 228px;
    max-width: 228px;
  }
`
export const RequestPayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0px 24px 0px 24px;
  @media (max-width: 1023px) {
    min-width: 228px;
    max-width: 228px;
  }
`

export const MobileRequestPayout = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
  padding: 12px 16px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
`
