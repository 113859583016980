import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { landingPageUrl } from 'src/Constants/links'
import Button from 'src/Components/Buttons/Button'

import {
  ReferralLinkNotExistBlock,
  ReferralLinkNotExistHead,
  ReferralLinkNotExistText,
  ReferralLinkNotExistButton,
} from './styles'

const ReferralLinkNotExist: FC = () => {
  const { t } = useTranslation()

  return (
    <ReferralLinkNotExistBlock>
      <ReferralLinkNotExistHead>
        {t('referAndEarn.thisReferralLinkNotExist')}
      </ReferralLinkNotExistHead>

      <ReferralLinkNotExistText>
        {t('referAndEarn.checkIfYourInvitationLinkIsCorrect')}
      </ReferralLinkNotExistText>

      <ReferralLinkNotExistButton>
        <a href={landingPageUrl} target='_blank' rel='noopener noreferrer'>
          <Button type='white'>{t('referAndEarn.goToSoundCampaign')}</Button>
        </a>
      </ReferralLinkNotExistButton>
    </ReferralLinkNotExistBlock>
  )
}
export default ReferralLinkNotExist
