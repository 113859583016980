import React, { FC } from 'react'

import styled from 'styled-components'

import { PrimaryButton } from 'src/Components/Buttons/PrimaryButton'
import MusicIcon from 'src/Assets/Svg/upsell/Music.svg?react'
import TikTokIcon from 'src/Assets/Svg/upsell/TikTok.svg?react'

import {
  CampaignUpsellButtonContent,
  CampaignUpsellButtonsContainer,
  CampaignUpsellContainer,
  CampaignUpsellTextContainer,
  CampaignUpsellTitle,
} from './styles'

import { CampaignUpsellProps } from '.'

const Container = styled(CampaignUpsellContainer)`
  border: 1px solid ${({ theme }) => theme.button.primaryText};
`

const Text = styled(CampaignUpsellTitle)`
  font-size: 14px;
  font-weight: 400;
`
const Button = styled(PrimaryButton)`
  min-width: 280px;
`
const ButtonContent = styled(CampaignUpsellButtonContent)`
  color: ${({ theme }) => theme.button.primaryText};
  svg path {
    fill: ${({ theme }) => theme.button.primaryText};
  }
`

type Props = Pick<CampaignUpsellProps, 'onUpsellTiktok' | 'onPromoteNewTrack'>

export const CampaignUpsellPositive: FC<Props> = ({
  onUpsellTiktok,
  onPromoteNewTrack,
}) => (
  <Container>
    <CampaignUpsellTextContainer>
      <CampaignUpsellTitle>Good progress!</CampaignUpsellTitle>
      <Text>
        Your track is getting noticed with a couple of placements so far. Let’s
        keep building your momentum.
      </Text>
    </CampaignUpsellTextContainer>
    <CampaignUpsellButtonsContainer>
      <Button onClick={onPromoteNewTrack}>
        <ButtonContent>
          <MusicIcon />
          <div>Promote a New Track</div>
        </ButtonContent>
      </Button>
      <Button onClick={onUpsellTiktok}>
        <ButtonContent>
          <TikTokIcon />
          <div>Expand Reach on TikTok</div>
        </ButtonContent>
      </Button>
    </CampaignUpsellButtonsContainer>
  </Container>
)
