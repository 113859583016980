import {
  NO_TRACKS_WERE_FOUND,
  SEARCH_ERROR_BY_NAME,
  SEARCH_ERROR_SAME_TIKTOK_TRACK,
  SEARCH_ERROR_SERVICE_UNAVAILABLE,
} from 'src/Constants/constants'

import { ErrorCode } from '../types'

export enum TikTokTrackSearchErrorEnum {
  UserLinkError = 'UserLinkError',
  WrongPlatformError = 'WrongPlatformError',
  NotOfficialTrackError = 'NotOfficialTrackError',
  WrongFirstCharacter = 'WrongFirstCharacter',
  DefaultError = 'DefaultError',
  NoTracksWereFound = 'NoTracksWereFound',
  ExistingCampaignTrack = 'ExistingCampaignTrack',
}

interface GetTiktokTrackErrorMessage {
  thrownErrorMessage?: string
  code?: number
  responseErrorMessage?: string
}

export const getTiktokTrackErrorMessage = ({
  thrownErrorMessage,
  code,
  responseErrorMessage,
}: GetTiktokTrackErrorMessage): string => {
  if (code === ErrorCode.Conflict) {
    return SEARCH_ERROR_SAME_TIKTOK_TRACK
  }

  if (responseErrorMessage === 'Tiktok campaign already exists') {
    return TikTokTrackSearchErrorEnum.ExistingCampaignTrack
  }

  if (thrownErrorMessage === TikTokTrackSearchErrorEnum.NoTracksWereFound) {
    return NO_TRACKS_WERE_FOUND
  }

  if (
    thrownErrorMessage === SEARCH_ERROR_BY_NAME ||
    thrownErrorMessage === 'timeoutError'
  ) {
    return SEARCH_ERROR_SERVICE_UNAVAILABLE
  }

  if (thrownErrorMessage === TikTokTrackSearchErrorEnum.NotOfficialTrackError) {
    return TikTokTrackSearchErrorEnum.NotOfficialTrackError
  }

  if (responseErrorMessage === 'wrong-first-character') {
    return TikTokTrackSearchErrorEnum.WrongFirstCharacter
  }

  if (responseErrorMessage === 'profile-tiktok-link') {
    return TikTokTrackSearchErrorEnum.UserLinkError
  }

  if (responseErrorMessage === 'not-tiktok-link') {
    return TikTokTrackSearchErrorEnum.WrongPlatformError
  }

  return TikTokTrackSearchErrorEnum.DefaultError
}
