import { FC, useState } from 'react'

import { Desktop, Mobile } from 'src/Components/Responsive'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import AddPlaylistModal from '../AddPlaylistModal'
import FindPlaylistMobile from '../FindPlaylistMobile'
import { PlaylistEditModalWrapper } from '../PlaylistEditModal/PlaylistEditModalWrapper'

import { RefreshButton } from './RefreshButton'
import { PlaylistTabs } from './PlaylistTabs'
import { PlaylistsList } from './PlaylistList/PlaylistList'

import {
  CardBody,
  ButtonsContainer,
  Table,
  TabTriggersOverflow,
  Card,
} from './styles'

const PlayList: FC = () => {
  const { setSearch } = useCustomSearchParams()
  const [activeTab, setActiveTab] = useState(1)

  const handleEdit = (id: number): void => {
    setSearch({ editPlaylist: String(id) })
  }

  return (
    <>
      <Card>
        <Desktop>
          <CardBody>
            <ButtonsContainer>
              <AddPlaylistModal />
              <RefreshButton />
            </ButtonsContainer>
            <PlaylistTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <Table>
              <PlaylistsList active={activeTab} onGenresEdit={handleEdit} />
            </Table>
          </CardBody>
        </Desktop>
        <Mobile>
          <TabTriggersOverflow>
            <PlaylistTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          </TabTriggersOverflow>
          <ButtonsContainer>
            <FindPlaylistMobile />
            <RefreshButton />
          </ButtonsContainer>
          <PlaylistsList active={activeTab} onGenresEdit={handleEdit} />
        </Mobile>
      </Card>
      <PlaylistEditModalWrapper />
    </>
  )
}

export default PlayList
