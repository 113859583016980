import { APP } from 'src/Configs/App'
import { rtkApi } from 'src/Services/rtkApi'
import { UserNotification } from 'src/Types'
import { toReversed } from 'src/Constants/functions'

export const artistNotificationsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getArtistNotifications: builder.query<UserNotification[], void>({
      query: () => `${APP.SERVER}/artist/notifications`,
      transformResponse: (response: UserNotification[] = []) =>
        toReversed(response),
      providesTags: [{ type: 'ArtistNotifications', id: 'LIST' }],
    }),

    markArtistNotificationsAsRead: builder.mutation<boolean, void>({
      query: () => ({
        url: `${APP.SERVER}/artist/notifications`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'ArtistNotifications', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetArtistNotificationsQuery,
  useMarkArtistNotificationsAsReadMutation,
} = artistNotificationsApi
