import React, { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PrimaryHeader } from 'src/Components/Header/Components/PrimaryHeader'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getArtistBalance,
  getIsUserLoading,
} from 'src/Redux/auth-process/userSlice/selectors'
import { CuratorsRouteEnum } from 'src/Router/CuratorComponentsNewUI'
import Exit from 'src/Assets/Svg/header-icons/exit.svg?react'
import Settings from 'src/Assets/Svg/header-icons/settingsNew.svg?react'
import { useLogOut } from 'src/Hooks/useLogOut'

import { HeaderContainerProps } from '../HeaderContainer'
import { useCuratorNotifications } from '../CuratorHeaderContainer/useCuratorNotifications'

export const CuratorHeaderContainer: FC<HeaderContainerProps> = ({
  withShadow,
  onSidebarToggleClick,
}) => {
  const { t } = useTranslation()
  const balance = useAppSelector(getArtistBalance)
  const navigate = useNavigate()
  const isBalanceLoading = useAppSelector(getIsUserLoading)
  const { handleLogOut } = useLogOut()
  const onSettingsClick = (): void => {
    navigate(CuratorsRouteEnum.Settings)
  }

  const onBalanceClick = (): void => {
    navigate(CuratorsRouteEnum.Balance)
  }

  const menuItems = [
    {
      title: t('header.settings'),
      Icon: Settings,
      onClick: onSettingsClick,
    },
    {
      title: t('header.logout'),
      Icon: Exit,
      onClick: handleLogOut,
    },
  ]

  const {
    sortedCuratorNotifications,
    hasNewCuratorNotifications,
    isHeaderNotificationsPopupVisible,
    onNotificationsButtonClick,
  } = useCuratorNotifications()

  return (
    <PrimaryHeader
      withShadow={withShadow}
      onSidebarToggleClick={onSidebarToggleClick}
      onBalanceButtonClick={onBalanceClick}
      balance={balance}
      isBalanceButtonLoading={isBalanceLoading}
      menuItems={menuItems}
      hasNewNotifications={hasNewCuratorNotifications}
      notifications={sortedCuratorNotifications}
      isHeaderNotificationsPopupVisible={isHeaderNotificationsPopupVisible}
      onNotificationsButtonClick={onNotificationsButtonClick}
    ></PrimaryHeader>
  )
}
