import { FC, useState } from 'react'

import styled from 'styled-components'

import { TextLink } from 'src/Components/TextLink'
import { useAppSelector } from 'src/Hooks/redux'
import { PrimaryAlert } from 'src/Components/PrimaryAlert'
import { PrimaryDottedButton } from 'src/Components/Buttons/PrimaryDottedButton'
import i18n from 'src/Localization/i18n'
import { useLazySendEmailToConfirmAccountQuery } from 'src/Redux/auth-process/emailApi'
import { getUserEmail } from 'src/Redux/auth-process/userSlice/selectors'

import { CHANGE_EMAIL_LINK } from '../index'

const StyledPrimaryDottedButton = styled(PrimaryDottedButton)`
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme.button.thirdText} !important;
`
const YellowPrimaryDottedButton = styled(StyledPrimaryDottedButton)`
  color: ${({ theme }) => theme.button.orangeText} !important;
  & > div {
    border-bottom: 1px dashed ${({ theme }) => theme.button.orangeText};
  }
`

const StyledTextLink = styled(TextLink)`
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-bottom: 1px dashed ${({ theme }) => theme.button.thirdText};
  color: ${({ theme }) => theme.button.thirdText} !important;
  & > div {
    border-bottom: 1px dashed ${({ theme }) => theme.button.thirdText};
  }
  &:hover {
    border-bottom: 1px dashed transparent;
  }
  &:focus-visible {
    outline: none;
  }
`
const DottedButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const DescriptionText = styled.div`
  color: ${({ theme }) => theme.button.orangeText};
  font-size: 14px;
  font-weight: 400;
  padding-top: 14px;
  padding-bottom: 10px;
`

export const EmailConfirmationWarning: FC = () => {
  const email = useAppSelector(getUserEmail)
  const [isMailSent, setIsMailSent] = useState(false)
  const [sendEmail, { isLoading }] = useLazySendEmailToConfirmAccountQuery()

  const onSendAgain = (): void => {
    sendEmail().then(() => {
      setIsMailSent(true)
    })
  }

  return (
    <PrimaryAlert
      type='warning'
      hasCross={false}
      title={i18n.t('campaignsPage.emailConfirmation')}
      renderButtons={() => (
        <DottedButtonWrapper>
          {!isMailSent && (
            <StyledTextLink href={CHANGE_EMAIL_LINK}>
              {i18n.t('campaignsPage.updateEmail')}
            </StyledTextLink>
          )}
          {!isMailSent && (
            <YellowPrimaryDottedButton
              disabled={isLoading}
              onClick={onSendAgain}
            >
              {i18n.t('campaignsPage.sendAgain')}
            </YellowPrimaryDottedButton>
          )}
          {isMailSent && (
            <DescriptionText>
              {i18n.t('campaignsPage.checkEmailSuccess')}
            </DescriptionText>
          )}
        </DottedButtonWrapper>
      )}
    >
      {i18n.t('campaignsPage.checkYourEmail', { email })}
    </PrimaryAlert>
  )
}
