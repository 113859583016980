import React, { FC } from 'react'

import styled from 'styled-components'
import { Skeleton } from 'antd'
import { SkeletonNodeProps } from 'antd/es/skeleton/Node'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { CampaignStatusEnum, Review } from 'src/Types'

import { InfoBlock } from '../InfoBlock'
import { ReviewsList } from '../ReviewsList'
import { StatsBlockContainer } from '../StatsBlockContainer'
import { CampaignUpsell } from '../CampaignUpsell'

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`

const StyledSkeleton = styled(({ className, ...rest }: SkeletonNodeProps) => (
  <Skeleton.Input {...rest} rootClassName={className} active />
))`
  &.ant-skeleton-block {
    border-radius: 16px;
  }
  &.ant-skeleton {
    flex-grow: 1 !important;
    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      width: 100% !important;
    }
  }
  & > .ant-skeleton-input {
    width: 100% !important;

    height: 240px !important;
    border-radius: 16px !important;

    @media (max-width: ${MAX_MOBILE_WIDTH}) {
      width: 100% !important;
    }
  }
`

interface ResultSectionProps {
  loading: boolean
  reviews?: Array<Review>
  reviewsCount?: number
  placementsCount?: number
  campaignStatus?: CampaignStatusEnum
  userId?: number
  campaignId?: number
  trackName?: string
  trackAuthorName?: string
  trackCoverUrl?: string
  startDate?: string
  onStartCampaignClick?: () => void
  onOpenIntercom: (message: string) => void
  onPromoteNewTrack: () => void
  onRelaunch: () => void
  onUpsellTiktok: () => void
}

export const ResultSection: FC<ResultSectionProps> = ({
  reviews,
  loading,
  reviewsCount,
  placementsCount,
  campaignStatus,
  userId,
  campaignId,
  trackAuthorName,
  trackCoverUrl,
  trackName,
  startDate,
  onOpenIntercom,
  onPromoteNewTrack,
  onRelaunch,
  onUpsellTiktok,
  onStartCampaignClick,
}) => {
  if (loading) {
    return <StyledSkeleton />
  }

  if (!campaignStatus) {
    return null
  }

  const showCampaignUpsell =
    campaignStatus === CampaignStatusEnum.ENDED &&
    typeof placementsCount === 'number'

  return (
    <ResultWrapper>
      {showCampaignUpsell && (
        <CampaignUpsell
          onOpenIntercom={onOpenIntercom}
          onPromoteNewTrack={onPromoteNewTrack}
          onRelaunch={onRelaunch}
          onUpsellTiktok={onUpsellTiktok}
          placementsCount={placementsCount}
        />
      )}
      <StatsBlockContainer
        reviewsCount={reviewsCount}
        placementsCount={placementsCount}
        campaignStatus={campaignStatus}
      />

      <InfoBlock
        startDate={startDate}
        campaignStatus={campaignStatus}
        reviewsCount={reviews?.length}
        onStartCampaignClick={onStartCampaignClick}
      />
      <ReviewsList
        reviews={reviews}
        userId={userId}
        campaignId={campaignId}
        trackName={trackName}
        trackAuthorName={trackAuthorName}
        trackCoverUrl={trackCoverUrl}
      />
    </ResultWrapper>
  )
}
