import { useCallback } from 'react'

import { useDebouncedCallback } from 'use-debounce'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  setTiktokTrackToInitialState,
  setTiktokTracksToInitialState,
} from 'src/Redux/tiktokTrack-process'
import { TikTokTrack } from 'src/Types'
import { usePageViewEvent } from 'src/Hooks/Analytics/useTrackEvents'
import { searchTikTokTrackNoAuth } from 'src/Redux/tiktokTrack-process/api-actions'
import { getIsTikTokTrackUrl } from 'src/Constants/functions'
import { DEBOUNCE_TIMER_1000 } from 'src/Constants/constants'
import {
  getAllSearchErrorSelector,
  getIsLoadingTikTok,
  getTikTokTracksSelector,
} from 'src/Redux/tiktokTrack-process/selectors'
import { SearchTrackFormDataProps } from 'src/Components/SearchForm/TrackSearchAuth'

interface UseSearchTrackResponse {
  onClearTracks: () => void
  onTrackSearchHandler: ({ searchInput }: SearchTrackFormDataProps) => void
  tracks: TikTokTrack[]
  error?: string
  loading: boolean
}

export const useTiktokWidgetSearch = (): UseSearchTrackResponse => {
  const dispatch = useAppDispatch()
  const tikTokTracks = useAppSelector(getTikTokTracksSelector)
  const searchError = useAppSelector(getAllSearchErrorSelector)
  const isLoadingTikTok = useAppSelector(getIsLoadingTikTok)

  const { enteredTrackUrlEventHandler, startedEnterTrackEventHandler } =
    usePageViewEvent()

  const onClearTracks = useCallback((): void => {
    dispatch(setTiktokTracksToInitialState())
    dispatch(setTiktokTrackToInitialState())
  }, [dispatch])

  const searchTracks = ({
    searchInput: searchInputValue,
  }: SearchTrackFormDataProps): void => {
    void dispatch(searchTikTokTrackNoAuth({ searchText: searchInputValue }))
  }

  const onTrackSearchHandler = useDebouncedCallback(
    ({ searchInput }: SearchTrackFormDataProps) => {
      if (!searchInput) {
        onClearTracks()
        return
      }
      searchTracks({ searchInput })

      if (getIsTikTokTrackUrl(searchInput)) {
        enteredTrackUrlEventHandler()
      } else {
        startedEnterTrackEventHandler()
      }
    },
    DEBOUNCE_TIMER_1000,
  )

  return {
    onClearTracks,
    onTrackSearchHandler,
    error: searchError || '',
    tracks: tikTokTracks || [],
    loading: isLoadingTikTok,
  }
}
