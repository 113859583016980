import { FC } from 'react'

import loader from 'src/Assets/Gif/loader.gif'

import { Loader, LoaderWrapper } from './styled'

interface InfinityLoaderProps {
  isLoading: boolean
  className?: string
}

const InfinityLoader: FC<InfinityLoaderProps> = ({ isLoading, className }) => {
  if (isLoading) {
    return (
      <LoaderWrapper className={className}>
        <Loader src={loader} alt='loader' />
      </LoaderWrapper>
    )
  }
  return null
}

export default InfinityLoader
