export const reviewed = 'reviewed'
export const missed = 'missed'
export const payment = 'payment'
export const payout = 'payout'
export const withdraw = 'withdraw'
export const rewardWithPlacement = 'reward_with_placement'
export const rewardWithoutPlacement = 'reward_without_placement'
export const thankValue = 'thank'
export const offensiveValue = 'offensive'
export const poorValue = 'poor'
export const gaReferrerBlacklist = ['google', 'soundcamps', 'spotify']
export const accepted = 'accepted'
export const awaiting = 'awaiting_review'
export const completed = 'completed'
export const rejected = 'canceled_by_artist'
export const declined = 'declined'
export const activeTabStorage = 'active_tab'
export const DEBOUNCE_TIMER_300 = 300
export const DEBOUNCE_TIMER_500 = 500
export const DEBOUNCE_TIMER_1000 = 1000
export const DEBOUNCE_TIMER_1500 = 1000
export const MIN_PAYOUT_AMOUNT = 20
export const API_TIMEOUT_15000 = 15000
export const ALBUM_IMAGES = 2
export const NOT_FIRST_ITEM = 2
export const GA_LENGTH = 6
export const SEARCH_ERROR_BY_NAME =
  'Search by name temporary unavailable. Please, use search by URL'
export const SEARCH_ERROR_SERVICE_UNAVAILABLE =
  'Oops! The service is temporarily unavailable. Please try again later.'
export const SEARCH_ERROR_NOT_OFFICIAL_TRACK =
  'Oops! The link you used is not the official one for this track. Please enter the official song link provided by your distributor, such as Tunecore, Distrokid, or another similar service.'
export const SEARCH_ERROR_SAME_TIKTOK_TRACK =
  "Oops! Looks like you've already created a campaign for this track. Go back to the Campaigns list to find it, or search for a different track to promote."
export const NO_TRACKS_WERE_FOUND = 'No tracks were found'
export const DEFAULT_PERIOD_TO_START_CAMPAIGN = 1
export const TRACKS_FEED_REVIEW_TIME_LEFT = 15.02
export const ROUND_TEN = 10
export const FIFTY_PERCENT = 50
export const EIGHTY_PERCENT = 80
export const ANY_LANGUAGE_ID = 3
export const NO_VOCAL = 2
export const DEBOUNCE_DELAY_500 = 500
export const DEBOUNCE_DELAY_1000 = 1000
export const HUNDRED_PERCENT = 100
export const VAT_PERCENT = 0.18
export const FIXED_BY = 2
export const SPOTIFY_BUDGET_STEP = 10
export const NAVIGATE_BACK_INDEX = -1
export const PAYPAL_SAVED = 'PayPal_saved'
export const NOTIFICATIONS_WIDTH_MOBILE = 374
export const NOTIFICATIONS_WIDTH_DESKTOP = 480
export const HORIZONTAL_AMOUNT = 3
