import { FC } from 'react'

import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { CampaignPlatformTypeEnum } from 'src/Types'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { CreateCampaignBreadcrumbsAuth } from '../../Components/CreateCampaignBreadcrumbsAuth'
import { FaqComponent } from '../../Components/FaqComponent'
import { SelectedNewTrack } from '../SearchTrackAuth/SelectedNewTrack'

import { useSelectedTrackAuth } from './useSelectedTrackAuth'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 40px;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    gap: 16px;
  }
`

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 1280px;
  flex-direction: column;
  gap: 80px;
  align-items: center;
`

const StyledFaq = styled(FaqComponent)`
  align-self: center;
`

export const SelectedTrackAuth: FC = () => {
  const { platform } = useParams<{ platform: CampaignPlatformTypeEnum }>()

  const { spotifyTrack, tikTokTrack, loading } = useSelectedTrackAuth()

  const isTikTok = platform === CampaignPlatformTypeEnum.TIKTOK

  return (
    <Container>
      <CreateCampaignBreadcrumbsAuth />
      <SearchContainer>
        <SelectedNewTrack
          spotifyTrack={spotifyTrack}
          tikTokTrack={tikTokTrack}
          loading={loading}
        />
        {isTikTok && <StyledFaq />}
      </SearchContainer>
    </Container>
  )
}
