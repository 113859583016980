import React, { FC } from 'react'

import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { useSpotifyCampaignDetails } from 'src/Redux/campaignDetailsApi/useSpotifyCampaignDetails'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import {
  hasPayedTikTokCampaigns,
  useMetadataQuery,
} from 'src/Redux/metadataApi'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

import { SpotifyDetailsBreadcrumbs } from './Components/SpotifyDetailsBreadcrumbs'
import { ResultSection } from './Components/ResultSection'
import { SummarySection } from './Components/SummarySection'
import { useSpotifyCampaignDetailsPage } from './useSpotifyCampaignDetailsPage'
import { Survey } from './Components/Survey'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    gap: 40px;
    padding-bottom: 56px;
  }
`

const Content = styled.div`
  display: flex;
  max-width: 1120px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    flex-direction: column;
  }
`

export const SpotifyCampaignDetailsPage: FC = () => {
  const navigate = useNavigate()
  const { showNewMessage, update } = useIntercom()

  const { campaignDetails, isLoading } = useSpotifyCampaignDetails()
  const { data } = useMetadataQuery()
  const isTiktokPayed = hasPayedTikTokCampaigns(data)
  const { onRelaunchCampaign, onUpsellCampaign } =
    useSpotifyCampaignDetailsPage()
  const userId = useSelector(getUserId)
  const genresList = campaignDetails?.Track?.TrackGenres.map(
    (genre) => genre?.Genre?.genre_name || '',
  )

  const onRelaunchCampaignClick = (): void => {
    campaignDetails?.id && onRelaunchCampaign(campaignDetails?.id)
  }

  const onUpsellCampaignClick = (): void => {
    campaignDetails?.Track?.track_name &&
      onUpsellCampaign(campaignDetails?.Track?.track_name)
  }

  const onStartCampaignClick = (): void => {
    navigate(`${ArtistRoutesEnum.SpotifySchedule}/${campaignDetails?.id}`)
  }

  const onOpenIntercom = (message: string): void => {
    update({
      // now not work on intercom side
      customAttributes: {
        campaignId: String(campaignDetails?.id),
      },
      // work on intercom side
      companies: [
        {
          companyId: String(campaignDetails?.id),
        },
      ],
    })
    showNewMessage(message)
  }

  const onPromoteNewTrack = (): void => {
    navigate(ArtistRoutesEnum.ChooseCreateCampaignPlatformSpotify)
  }

  return (
    <PageContainer>
      <Survey />
      <SpotifyDetailsBreadcrumbs />
      <Content>
        <SummarySection
          campaignStatus={campaignDetails?.status}
          loading={isLoading}
          spent={campaignDetails?.spent}
          budget={campaignDetails?.budget}
          trackCoverUrl={campaignDetails?.Track?.track_image}
          trackName={campaignDetails?.Track?.track_name}
          trackAuthorName={campaignDetails?.Track?.artist_name}
          startDate={campaignDetails?.start_date}
          endDate={campaignDetails?.end_date}
          genres={genresList}
          isTiktokPayed={isTiktokPayed}
          onRelaunchCampaign={onRelaunchCampaignClick}
          onUpsellCampaign={onUpsellCampaignClick}
          campaignType={campaignDetails?.type}
          refund={campaignDetails?.refund}
          trackActiveCampaignsCount={campaignDetails?.trackActiveCampaignsCount}
        />
        <ResultSection
          startDate={campaignDetails?.start_date}
          campaignStatus={campaignDetails?.status}
          loading={isLoading}
          reviewsCount={campaignDetails?.reviewed_count}
          placementsCount={campaignDetails?.playlists_adds}
          reviews={campaignDetails?.Reviews}
          userId={Number(userId)}
          campaignId={campaignDetails?.id}
          trackName={campaignDetails?.Track?.track_name}
          trackAuthorName={campaignDetails?.Track?.artist_name}
          trackCoverUrl={campaignDetails?.Track?.track_image}
          onStartCampaignClick={onStartCampaignClick}
          onOpenIntercom={onOpenIntercom}
          onPromoteNewTrack={onPromoteNewTrack}
          onRelaunch={onRelaunchCampaignClick}
          onUpsellTiktok={onUpsellCampaignClick}
        />
      </Content>
    </PageContainer>
  )
}
