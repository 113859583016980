import { FC } from 'react'

import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { CampaignPlatformTypeEnum } from 'src/Types'
import LikesIcon from 'src/Assets/Svg/campaignDetails/spotify_stats.svg?react'
import VideosIcon from 'src/Assets/Svg/campaignDetails/tiltok_stats.svg?react'
import { MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { StatsSection, StatsSectionProps } from './components/index'

export interface StatsBlockProps {
  sections: StatsSectionProps[]
  platform?: CampaignPlatformTypeEnum
  $isHorizontal?: boolean
}

interface Props {
  $isHorizontal?: boolean
}

const StatsBlockContainer = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 24px;
  background-color: ${({ theme }) => theme.common.primaryBg};
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.thirdText};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);

  @media (max-width: ${MAX_TABLET_WIDTH}) {
    box-shadow: none;
    display: ${(props: Props) => (props.$isHorizontal ? 'flex' : 'grid')};
    grid-template-columns: ${(props: Props) =>
      !props.$isHorizontal && 'repeat(2, 1fr)'};
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
`
const getPlatformIcon = (
  platform: CampaignPlatformTypeEnum,
): JSX.Element | null => {
  switch (platform) {
    case CampaignPlatformTypeEnum.SPOTIFY:
      return <LikesIcon data-testid='likes-icon' />
    case CampaignPlatformTypeEnum.TIKTOK:
      return <VideosIcon data-testid='videos-icon' />
    default:
      return null
  }
}

export const StatsBlock: FC<StatsBlockProps> = ({
  sections,
  platform,
  $isHorizontal,
}) => {
  const isTablet = useMediaQuery({ maxWidth: MAX_TABLET_WIDTH })

  return (
    <StatsBlockContainer $isHorizontal={$isHorizontal}>
      {platform && !isTablet ? (
        <IconWrapper>{getPlatformIcon(platform)}</IconWrapper>
      ) : null}
      {sections.map((section) => (
        <StatsSection key={section.title} {...section} />
      ))}
    </StatsBlockContainer>
  )
}
