import {
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'

import i18n from 'src/Localization/i18n'

import {
  getIsSpotifyUrl,
  getSpotifyApiUrl,
} from '../spotifyTracks-process/utils'
import { ErrorCode } from '../types'

type GetSpotifyTracksBody = {
  search: string
  token: string
}

export type SpotifyTrack = {
  id?: string
  trackName?: string
  trackAuthor?: string
  trackImage?: string
  trackDuration?: number
  trackHref?: string
  previewUrl?: string
  artistSpotifyId?: string
}

type AlbumArtist = {
  external_urls?: {
    spotify: string
  }
  href?: string
  id?: string
  name?: string
  type?: string
  uri?: string
}

type AlbumImage = {
  height?: number
  url?: string
  width?: number
}

type ExternalUrls = {
  spotify: string
}
type Album = {
  album_type?: string
  artists?: AlbumArtist[]
  available_markets?: string[]
  external_urls?: ExternalUrls
  href?: string
  id?: string
  images: AlbumImage[]
  name?: string
  release_date?: string
  release_date_precision?: string
  total_tracks?: number
  type?: string
  uri?: string
}

type SpotifyTrackResponse = {
  id?: string
  album?: Album
  artists?: AlbumArtist[]
  available_markets?: string[]
  disc_number?: number
  duration_ms: number
  explicit?: boolean
  external_ids: {
    isrc: string
  }
  external_urls?: ExternalUrls
  href?: string
  is_local?: boolean
  name?: string
  popularity?: number
  preview_url?: string
  track_number?: number
  type?: string
  uri?: string
}
type GetSpotifyTracksResponse = {
  tracks: {
    href: string
    items: SpotifyTrackResponse[]
    limit: number
    next: string | null
    previous: string | null
    offset: number
    total: number
  }
}

const isInvalidIdError = (error: FetchBaseQueryError): boolean =>
  'error' in (error.data as Record<string, string | Record<string, string>>) &&
  (error.data as Record<string, Record<string, string>>)?.error?.message ===
    'invalid id'

export const spotifyApi = createApi({
  baseQuery: fetchBaseQuery(),
  reducerPath: 'spotifyApi',
  tagTypes: ['SpotifyTracks'],
  endpoints: (builder) => ({
    getSpotifyTracks: builder.query<SpotifyTrack[], GetSpotifyTracksBody>({
      query: ({ search, token }) => ({
        url: getSpotifyApiUrl(search).url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformErrorResponse(baseQueryReturnValue, _, arg) {
        if (isInvalidIdError(baseQueryReturnValue)) {
          return {
            status: ErrorCode.NotFound,
            message:
              'Oops! It looks like the URL you entered is not valid. Please make sure you have entered a correct web address and try searching again.',
          }
        }

        if (
          baseQueryReturnValue.status === ErrorCode.NotFound &&
          !getIsSpotifyUrl(arg.search)
        ) {
          return {
            status: ErrorCode.NotFound,
            message: i18n.t('createCampaignPage.trackNameSearchUnavailable'),
          }
        }
        if (baseQueryReturnValue.status && getIsSpotifyUrl(arg.search)) {
          return {
            status: ErrorCode.NotFound,
            message: i18n.t('createCampaignPage.urlSearchUnavailable'),
          }
        }
        return {
          status: ErrorCode.Bed_Request,
          message: i18n.t('createCampaignPage.tryLater'),
        }
      },
      transformResponse: (
        response: GetSpotifyTracksResponse | SpotifyTrackResponse,
      ) => {
        if ('tracks' in response) {
          return response.tracks.items.map((track) => ({
            id: track.id,
            trackName: track.name,
            trackAuthor: track.artists?.[0].name,
            trackImage: track.album?.images?.[0].url,
            trackDuration: track.duration_ms,
            trackHref: track.href,
            previewUrl: track.preview_url,
            artistSpotifyId: track.artists?.[0].id,
          }))
        }
        return [
          {
            id: response.id,
            trackName: response.name,
            trackAuthor: response.artists?.[0].name,
            trackImage: response.album?.images?.[0].url,
            trackDuration: response.duration_ms,
            trackHref: response.href,
            previewUrl: response.preview_url,
            artistSpotifyId: response.artists?.[0].id,
          },
        ]
      },
      providesTags: ['SpotifyTracks'],
    }),
    clearSpotifyTracks: builder.mutation<never[], void>({
      queryFn() {
        return { data: [] }
      },
      onQueryStarted: (_, { dispatch }) => {
        dispatch(spotifyApi.util.resetApiState())
      },
      invalidatesTags: ['SpotifyTracks'],
    }),
  }),
})

export const {
  useLazyGetSpotifyTracksQuery,
  useClearSpotifyTracksMutation,
  useGetSpotifyTracksQuery,
} = spotifyApi
