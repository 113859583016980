import { Middleware, PayloadAction } from '@reduxjs/toolkit'

import {
  defaultSearchErrorTagManagerEvent,
  errorAddTrackEvent,
  searchUnavailableEvent,
} from 'src/Helpers/TagManager'
import {
  SEARCH_ERROR_BY_NAME,
  SEARCH_ERROR_NOT_OFFICIAL_TRACK,
  SEARCH_ERROR_SAME_TIKTOK_TRACK,
  SEARCH_ERROR_SERVICE_UNAVAILABLE,
} from 'src/Constants/constants'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { EventNameEnum } from 'src/Helpers/TagManager/tagManagerEnums'

import rootReducer from '../root-reducer'
import { RootState } from '../index'
import { ErrorCode } from '../types'

type Reducer = ReturnType<typeof rootReducer>

type MetaType = {
  requestStatus: 'pending' | 'fulfilled' | 'rejected'
}

type PayloadType = {
  code: number
  message: string
}

const tikTokSearchTrackActions = [
  'searchTikTokTrackAuth',
  'searchTikTokTrackNoAuth',
]

const eventMapCodes: Record<number, EventNameEnum> = {
  [ErrorCode.Conflict]: EventNameEnum.ErrorSameTrack,
  [ErrorCode.Unauthorized]: EventNameEnum.ErrorAccessToken,
}

const eventMapMessages: Record<string, EventNameEnum> = {
  [SEARCH_ERROR_NOT_OFFICIAL_TRACK]: EventNameEnum.ErrorOfficialTrack,
  [SEARCH_ERROR_SERVICE_UNAVAILABLE]: EventNameEnum.SearchUnavailable,
  [SEARCH_ERROR_BY_NAME]: EventNameEnum.SearchUnavailable,
  [SEARCH_ERROR_SAME_TIKTOK_TRACK]: EventNameEnum.ErrorSameTrack,
}

export const sendTikTokTrackEvents: Middleware<unknown, Reducer> =
  (store) =>
  (next) =>
  (action: PayloadAction<PayloadType, string, MetaType>) => {
    const { authentication, campaigns } = store.getState() as RootState
    const { user } = authentication.user
    const { amountOfCampaigns } = campaigns.main

    const userId = user?.id ? user.id : user?.curatorId
    const isFirstCampaign = user?.is_first_campaign
    const campaignNumber = amountOfCampaigns + 1

    const isTikTokSearchTrackAction = (actionType: string): boolean =>
      tikTokSearchTrackActions.some((searchTrackActions) =>
        actionType.includes(searchTrackActions),
      )

    const isRejectedResponse =
      action.meta?.requestStatus === 'rejected' &&
      isTikTokSearchTrackAction(action.type)

    if (isRejectedResponse) {
      const eventName =
        eventMapCodes[action.payload.code] ||
        eventMapMessages[action.payload.message]

      if (eventName) {
        eventName === EventNameEnum.SearchUnavailable
          ? searchUnavailableEvent({
              userId,
              isFirstCampaign,
              campaignNumber,
            })
          : defaultSearchErrorTagManagerEvent({
              userId,
              eventName,
              campaignPlatform: CampaignPlatformTypeEnum.TIKTOK,
            })
      } else {
        errorAddTrackEvent({
          userId,
          isFirstCampaign,
          campaignNumber,
          campaignPlatform: CampaignPlatformTypeEnum.TIKTOK,
        })
      }
    }

    return next(action)
  }
