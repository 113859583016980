import { FC, Suspense, lazy } from 'react'

import { Routes, Route } from 'react-router-dom'

import { SettingsPage } from 'src/Pages/Artist/SettingsPage'
import CuratorBalancePage from 'src/Pages/Curator/BalancePage'
import RankPage from 'src/Pages/Curator/RankPage'
import PlayListsPage from 'src/Pages/Curator/PlaylistsPage'

export enum CuratorsRouteEnum {
  Tracks = '/tracksFeed',
  Reviewed = '/reviewed',
  Review = '/review',
  Balance = '/balance',
  Playlists = '/playlists',
  Rank = '/rank',
  Settings = '/settings',
}

// please don't remove this  lazy import
const TracksFeed = lazy(() => import('src/Pages/Curator/TrackFeedPage'))
const TrackToReview = lazy(() => import('src/Pages/Curator/TrackToReviewPage'))
const ReviewedTrack = lazy(() => import('src/Pages/Curator/ReviewedTrackPage'))

export const CuratorComponentsNewUI: FC = () => (
  <Suspense fallback={<div></div>}>
    <Routes>
      <Route Component={TracksFeed} path={CuratorsRouteEnum.Tracks} />
      <Route Component={TracksFeed} path={'/'} />
      <Route
        Component={ReviewedTrack}
        path={`${CuratorsRouteEnum.Tracks}/:campaignId${CuratorsRouteEnum.Reviewed}`}
      />
      <Route
        Component={TrackToReview}
        path={`${CuratorsRouteEnum.Tracks}/:campaignId${CuratorsRouteEnum.Review}`}
      />
      <Route Component={CuratorBalancePage} path={CuratorsRouteEnum.Balance} />
      <Route Component={PlayListsPage} path={CuratorsRouteEnum.Playlists} />
      <Route Component={RankPage} path={CuratorsRouteEnum.Rank} />
      <Route Component={SettingsPage} path={CuratorsRouteEnum.Settings}>
        <Route path=':page' Component={SettingsPage} />
      </Route>

      <Route Component={TracksFeed} path='*' />
    </Routes>
  </Suspense>
)
