import React, { FC } from 'react'

import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'

import { CampaignStatusEnum } from 'src/Types'
import { CampaignPreModerationVideo } from 'src/Redux/campaignVideosApi/mapVideoToPreModerationStatus'
import { VideoDetailsCard } from 'src/Components/VideoDetailsCard'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

const MainInfoContainerVideosList = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background-color: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    box-shadow: none;
    border: none;
    padding: 0;
  }
`

interface VideosListProps {
  campaignStatus?: CampaignStatusEnum
  videos?: Array<CampaignPreModerationVideo>
}

export const VideosList: FC<VideosListProps> = ({
  campaignStatus,
  videos = [],
}) => {
  const { campaignID } = useParams<{ campaignID: string }>()
  const navigate = useNavigate()

  const videosCount = videos.length

  if (!campaignStatus) {
    return null
  }

  const isVideosListHidden =
    [
      CampaignStatusEnum.AWAITING_REVIEW,
      CampaignStatusEnum.PENDING,
      CampaignStatusEnum.SCHEDULED,
    ].includes(campaignStatus) ||
    ([
      CampaignStatusEnum.RUNNING,
      CampaignStatusEnum.STOPPED,
      CampaignStatusEnum.CANCELED_BY_ADMIN,
    ].includes(campaignStatus) &&
      !videosCount)

  if (isVideosListHidden) {
    return null
  }

  const onVideoDetailsCardClick = (videoId: number): void => {
    navigate(`${ArtistRoutesEnum.TiktokDetails}/${campaignID}/${videoId}`)
  }

  if (!videos.length) {
    return null
  }

  return (
    <MainInfoContainerVideosList>
      {videos.map((video) => (
        <VideoDetailsCard
          key={video.id}
          avatarUrl={video.userPhotoUrl}
          followers={video.followerCount}
          views={video.viewsCount}
          userName={video.nickname}
          videoStatus={video.status}
          onDetailsClick={() => onVideoDetailsCardClick(video.id)}
          videoPreModerationStatus={video.preModerationStatus}
        />
      ))}
    </MainInfoContainerVideosList>
  )
}
