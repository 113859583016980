import { FC, useMemo } from 'react'

import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import mobileUI from 'src/Assets/Webp/mobileUI.webp'
import tabletUI from 'src/Assets/Webp/tabletUI.webp'
import desktopUI from 'src/Assets/Webp/desktopUI.webp'
import mobileCuratorUI from 'src/Assets/Webp/mobile-curator.webp'
import tabletCuratorUI from 'src/Assets/Webp/tablet-curator.webp'
import desktopCuratorUI from 'src/Assets/Webp/desktop-curator.webp'
import { device } from 'src/Styled/variables'
import { Roles } from 'src/Constants/enums'

import { PrimaryButton } from '../Buttons/PrimaryButton'
import { FullScreenModal } from '../FullScreenModal'

const Container = styled.div`
  display: flex;
  max-width: 342px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.tabletMin} {
    max-width: 672px;
  }
  @media ${device.smallScreen} {
    max-width: 928px;
  }
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 342px;
  margin: 0 auto;
  @media ${device.tabletMin} {
    width: 402px;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  max-width: 420px;
`

const Text = styled(Title)`
  font-size: 14px;
  font-weight: 400;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid ${({ theme }) => theme.button.thirdBg};
`

const StyledImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
`

export type NewUIPopupProps = {
  newUIPopup?: boolean
  role?: Roles
  handleClickNewUIPopup?: () => void
}

const IMAGE_MAP: Record<string, Record<string, string>> = {
  [Roles.artist]: {
    mobile: mobileUI,
    tablet: tabletUI,
    desktop: desktopUI,
  },
  [Roles.curator]: {
    mobile: mobileCuratorUI,
    tablet: tabletCuratorUI,
    desktop: desktopCuratorUI,
  },
}

export const NewUIPopup: FC<NewUIPopupProps> = ({
  role,
  newUIPopup,
  handleClickNewUIPopup,
}) => {
  const { t } = useTranslation()

  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })
  const isDesktop = useMediaQuery({ minWidth: 1024 })

  const imageNewUI = useMemo(() => {
    if (isMobile) {
      return IMAGE_MAP[role || Roles.artist].mobile
    }
    if (isTablet) {
      return IMAGE_MAP[role || Roles.artist].tablet
    }
    if (isDesktop) {
      return IMAGE_MAP[role || Roles.artist].desktop
    }
    return tabletUI
  }, [isMobile, isTablet, isDesktop, role])

  if (newUIPopup) {
    return (
      <FullScreenModal open>
        <Wrapper>
          <Container>
            <TextContainer>
              <Title>{t('newUI.newUITitle')}</Title>
              <Text>{t('newUI.newUIText')}</Text>
            </TextContainer>
            <StyledPrimaryButton onClick={handleClickNewUIPopup}>
              {t('newUI.newUIButton')}
            </StyledPrimaryButton>
            <StyledImageContainer>
              <img src={imageNewUI} alt='newUI' />
            </StyledImageContainer>
          </Container>
        </Wrapper>
      </FullScreenModal>
    )
  }

  return null
}
