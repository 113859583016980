import { FC, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import {
  getHasAwaiting,
  getIsRecipient,
} from 'src/Redux/transactions-process/selectors'
import Button from 'src/Components/Buttons/Button'
import {
  getArtistBalance,
  getUserId,
  getUserSelector,
} from 'src/Redux/auth-process/userSlice/selectors'
import {
  payoutRequestedEvent,
  payoutRequestEvent,
} from 'src/Helpers/TagManager'
import { resetUserBalance } from 'src/Redux/auth-process/userSlice'
import { CustomEmbedRef } from 'src/Types'
import { getAllTransactions } from 'src/Redux/transactions-process/curatorBalanceHistorySlice/api-actions'
import { getTransactionsDetails } from 'src/Redux/transactions-process/api-actions'
import { useCuratorOrderPayoutMutation } from 'src/Redux/orderPayoutApi'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { CuratorSurveyAfterPayoutRequest } from '../../CuratorSurveyAfterPayoutRequest'

import { ButtonWrapper } from './styles'

const DELAY_5000 = 5000
const FIXED_BY = 2
const MIN_BALANCE = 20

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    flex-direction: row;
  }
`
const Text = styled.div`
  color: #646877;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
export const RequestButton: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const hasAwaiting = useAppSelector(getHasAwaiting)
  const recipient = useAppSelector(getIsRecipient)
  const currentBalance = useAppSelector(getArtistBalance)
  const user = useAppSelector(getUserSelector)
  const typeformRef = useRef<CustomEmbedRef | null>(null)
  const userId = useAppSelector(getUserId)
  const [orderPayout, { isLoading }] = useCuratorOrderPayoutMutation({
    fixedCacheKey: 'CuratorOrderPayout',
  })

  const isDisabled =
    hasAwaiting || Number(currentBalance) < MIN_BALANCE || !recipient

  const openSliderButton = (): void => {
    setTimeout(() => {
      typeformRef.current?.open()
    }, DELAY_5000)
  }

  const handlePayoutRequest = (): void => {
    if (!currentBalance) {
      return
    }
    orderPayout()
      .unwrap()
      .then((response) => {
        void dispatch(getAllTransactions(1))
        void dispatch(getTransactionsDetails())
        // Responce is empty, so we can't get transaction id
        userId && payoutRequestedEvent(currentBalance, 0, String(userId))
        openSliderButton()
        dispatch(resetUserBalance())
        payoutRequestEvent(user, currentBalance)
        return response
      })
  }

  const buttonText = hasAwaiting
    ? t('balancePage.payoutOnApproval')
    : t('balancePage.requestPayout')
  return (
    <>
      <ButtonWrapper>
        <Button
          loading={isLoading}
          type='green'
          disabled={isDisabled || isLoading}
          onClick={handlePayoutRequest}
        >
          {!isLoading ? buttonText : ''}
        </Button>
      </ButtonWrapper>
      <Container>
        <Text>{t('balancePage.minimumRequest')}</Text>
        <Text>${MIN_BALANCE.toFixed(FIXED_BY)}</Text>
      </Container>
      <CuratorSurveyAfterPayoutRequest typeformRef={typeformRef} />
    </>
  )
}
