import React from 'react'

import styled from 'styled-components'

import { TextLink } from 'src/Components/TextLink'

import { CommonText, ReviewPlaylist } from '../ReviewBlock'

const PlaylistInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PlaylistInfo: React.FC<ReviewPlaylist> = ({
  playlistName,
  playlistUrl,
}) => (
  <PlaylistInfoContainer>
    {playlistUrl ? (
      <TextLink href={playlistUrl}>{playlistName}</TextLink>
    ) : (
      <CommonText>{playlistName}</CommonText>
    )}
  </PlaylistInfoContainer>
)
