import { FC, useEffect } from 'react'

import styled from 'styled-components'
import { useDebounce } from 'use-debounce'

import { CampaignListItem } from 'src/Components/CampaignListItem'
import { newReleasedCampaignsListEvent } from 'src/Helpers/TagManager/CampaignsEvents'
import { useAppSelector } from 'src/Hooks/redux'
import { useCreateAndStartScheduleNewRelease } from 'src/Hooks/useCreateAndStartScheduleNewRelease'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { useGetNewReleaseQuery } from 'src/Redux/newReleaseApi'
import { CampaignPlatformTypeEnum, CampaignStatusEnum } from 'src/Types'
import {
  getCampaignsFilters,
  getCampaignsSearch,
} from 'src/Redux/campaignsActions-process'
import { DEBOUNCE_DELAY_1000 } from 'src/Constants/constants'

const Container = styled.div`
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.common.secondaryBorder};
  background-color: ${(props) => props.theme.common.primaryBg};
  margin: -8px;
  padding: 8px;
  box-sizing: content-box;
`

export const CampaignNewReleaseItem: FC = () => {
  const userId = useAppSelector(getUserId)
  const { data } = useGetNewReleaseQuery()
  const filters = useAppSelector(getCampaignsFilters)
  const search = useAppSelector(getCampaignsSearch)
  const [searchValue] = useDebounce(search, DEBOUNCE_DELAY_1000)
  const matchesSearch = searchValue
    ? data?.trackName?.toLowerCase().includes(searchValue) ||
      data?.artistName?.toLowerCase().includes(searchValue)
    : true
  const isFiltered = !filters?.spotify && filters?.tiktok
  const { createAndStartSchedule, loading } =
    useCreateAndStartScheduleNewRelease()

  useEffect(() => {
    if (data?.trackSpotifyId) {
      newReleasedCampaignsListEvent({ userId, trackId: data?.trackSpotifyId })
    }
  }, [data?.trackSpotifyId, userId])

  if (!data?.id || isFiltered || !matchesSearch) {
    return null
  }
  return (
    <Container>
      <CampaignListItem
        newRelease
        startLoading={loading}
        trackImage={data.trackImageUrl}
        trackName={data.trackName}
        artistName={data.artistName}
        status={CampaignStatusEnum.APPROVED}
        platform={CampaignPlatformTypeEnum.SPOTIFY}
        onStartCampaignClick={() => createAndStartSchedule()}
      />
    </Container>
  )
}
