import { FC } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { CreateNewCampaignContainer } from 'src/Components/CreateNewCampaignContainer'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import ArrowIcon from 'src/Assets/Svg/arrow.svg?react'
import { campaignsList, createCampaign } from 'src/Router/routes'
import { TrackSearchAuth } from 'src/Components/SearchForm/TrackSearchAuth'
import { HowToGetYourTrackURLModal } from 'src/Components/HowToGetYourTrackURLModal'
import { FaqComponent } from 'src/Components/FaqComponent'
import { useSearchTrackEvents } from 'src/Hooks/Analytics/useSearchTrackEvents'
import { IconComponent } from 'src/Components/IconComponent'

import { MAP_PLATFORM_TO_PLACEHOLDER, MAP_PLATFORM_TO_TITLE } from './helpers'
import { useSearchTrackAuth } from './useSearchTrackAuth'
import { SelectedNewCampaign } from './SelectedNewCampaign'
import { SuccessCreateCampaign } from './SelectedNewCampaign/SuccsesCreateCampaign'

import { ArrowBackButton, SubHeadForNewCampaignSearch } from './styles'

interface LocationState {
  successCreatedCampaign: boolean
}

const SearchNewCampaignByPlatform: FC = () => {
  const navigate = useNavigate()
  const { platform } = useParams<{ platform: CampaignPlatformTypeEnum }>()
  const { state } = useLocation() as {
    state: LocationState
  }
  const {
    onCheckTrack,
    onClearTracks,
    onTrackSearchHandler,
    tracks,
    hasSelectedTrack,
    error,
    loading,
  } = useSearchTrackAuth(platform)

  useSearchTrackEvents(CampaignTypeEnum.BASIC, platform)

  const handleBack = (): void => {
    onClearTracks()
    navigate(`${campaignsList}${createCampaign}`)
  }

  return (
    <CreateNewCampaignContainer onClick={onClearTracks}>
      <HowToGetYourTrackURLModal />
      <SubHeadForNewCampaignSearch isFAQ isHiddenOnMobile={Boolean(platform)}>
        <ArrowBackButton onClick={handleBack}>
          <ArrowIcon />
        </ArrowBackButton>
        <div>
          {MAP_PLATFORM_TO_TITLE[platform || CampaignPlatformTypeEnum.SPOTIFY]}
        </div>
        <IconComponent platform={platform} />
      </SubHeadForNewCampaignSearch>
      {!hasSelectedTrack && (
        <TrackSearchAuth
          isFAQ={Boolean(platform === CampaignPlatformTypeEnum.TIKTOK)}
          platform={platform || CampaignPlatformTypeEnum.SPOTIFY}
          placeholderText={
            MAP_PLATFORM_TO_PLACEHOLDER[
              platform || CampaignPlatformTypeEnum.SPOTIFY
            ]
          }
          tracks={tracks}
          loading={loading}
          errorMessage={error}
          onSubmit={onTrackSearchHandler}
          onCheckTrack={onCheckTrack}
          onClearTracks={onClearTracks}
          onClickBack={handleBack}
        />
      )}
      <SelectedNewCampaign />

      {Boolean(state?.successCreatedCampaign) && (
        <SuccessCreateCampaign platform={platform} />
      )}

      <FaqComponent platform={platform} />
    </CreateNewCampaignContainer>
  )
}
export default SearchNewCampaignByPlatform
