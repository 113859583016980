import React from 'react'

import { useTranslation } from 'react-i18next'

import { Collapse } from 'src/Components/Collapse'

import { PlaylistInfo } from '../PlaylistInfo'
import { AccentText, PlacementContainer, ReviewPlaylist } from '../ReviewBlock'

export interface MatchedPlaylistsProps {
  matchedTrackPlaylists: Array<ReviewPlaylist>
}

export const MatchedPlaylists: React.FC<MatchedPlaylistsProps> = ({
  matchedTrackPlaylists,
}) => {
  const { t } = useTranslation()

  return (
    <Collapse
      items={[
        {
          label: (
            <AccentText>{t('campaignDetailsPage.trackMatches')}</AccentText>
          ),
          children: (
            <PlacementContainer>
              {matchedTrackPlaylists.map((playlist) => (
                <PlaylistInfo
                  key={playlist.playlistName}
                  playlistName={playlist.playlistName}
                />
              ))}
            </PlacementContainer>
          ),
        },
      ]}
    />
  )
}
