import React, { ReactNode } from 'react'

import styled from 'styled-components'

import { PrimaryButton, PrimaryButtonProps } from '../PrimaryButton'

const StyledIconButton = styled(PrimaryButton)`
  width: 48px;
  height: 48px;
  padding: 0;
  flex-shrink: 0;

  svg {
    path {
      fill: ${({ theme }) => theme.button.thirdText};
    }
  }

  &:active {
    border: ${({ theme }) => `1px solid ${theme.button.secondaryBorder}`};
    svg {
      path {
        fill: ${({ theme }) => theme.button.fifthText};
      }
    }

    &:focus {
      outline: none;
      border: ${({ theme }) => `1px solid ${theme.button.primaryBorder}`};
    }
  }
`

export type IconButtonProps = Pick<
  PrimaryButtonProps,
  'disabled' | 'onClick' | 'className'
> & {
  children: ReactNode
}

export const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  disabled,
  children,
  className,
  ...props
}) => (
  <StyledIconButton
    {...props}
    onClick={onClick}
    disabled={disabled}
    className={className}
  >
    {children}
  </StyledIconButton>
)
