import { FC, useEffect, useMemo } from 'react'

import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { toggleActivatedEvent } from 'src/Helpers/TagManager'
import {
  getAllSearchErrorSelector,
  getIsLoadingTikTok,
  getTikTokTracksSelector,
} from 'src/Redux/tiktokTrack-process/selectors'
import { useTrackSearchForm } from 'src/Containers/SearchTrackByPlatform/TrackSearchForm/useTrackSearchForm'
import { PublicRoutesEnum } from 'src/Router/PublicComponentsNewUI'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'

import { mapTikTokTrack } from '../../helper'
import { SearchedTrack, TrackSearch } from '../../Components/TrackSearch'
import { CreateCampaignBreadcrumbsNoAuth } from '../../Components/CreateCampaignBreadcrumbsNoAuth'
import { HelmetTitle } from '../../Components/HelmetTitle'
import { NoAuthPageLayout } from '../../Components/NoAuthPageLayout'
import { FaqComponent } from '../../Components/FaqComponent'
import { TikTokTrackSearchError } from '../../Components/TikTokTrackSearchError'

const ContentContainer = styled.div`
  display: flex;
  max-width: 1280px;
  flex-direction: column;
  gap: 80px;
  align-items: center;
`

const TopContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 960px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

export const SearchTikTokTrackNoAuth: FC = () => {
  const { search } = useCustomSearchParams()
  const navigate = useNavigate()

  const tikTokTracks = useSelector(getTikTokTracksSelector)
  const isLoadingTikTok = useSelector(getIsLoadingTikTok)
  const searchError = useSelector(getAllSearchErrorSelector)

  const { debouncedFindTrack, setTracksToInitialStateHandler } =
    useTrackSearchForm(CampaignPlatformTypeEnum.TIKTOK)

  useEffect(() => {
    toggleActivatedEvent({
      platformName: CampaignPlatformTypeEnum.TIKTOK,
      campaignType: CampaignTypeEnum.FIRST,
      campaignNumber: 1,
    })
  }, [])

  const formattedTracks = useMemo<SearchedTrack[]>(
    () => tikTokTracks?.map(mapTikTokTrack) || [],
    [tikTokTracks],
  )
  const onSearch = (searchInput: string): void => {
    debouncedFindTrack(searchInput)
  }
  const onOutsideClick = (): void => {
    setTracksToInitialStateHandler()
  }

  const onItemClick = (trackId: string): void => {
    navigate(
      `${PublicRoutesEnum.CreateTikTokCampaign}/${trackId}?${search.toString()}`,
    )
  }

  return (
    <NoAuthPageLayout>
      <HelmetTitle />

      <ContentContainer>
        <TopContentContainer>
          <CreateCampaignBreadcrumbsNoAuth
            platform={CampaignPlatformTypeEnum.TIKTOK}
          />
          <TrackSearch
            platform={CampaignPlatformTypeEnum.TIKTOK}
            isLoading={isLoadingTikTok}
            onItemClick={onItemClick}
            tracks={formattedTracks}
            onSearch={onSearch}
            onOutsideClick={onOutsideClick}
            error={
              searchError && <TikTokTrackSearchError error={searchError} />
            }
          />
        </TopContentContainer>

        <FaqComponent />
      </ContentContainer>
    </NoAuthPageLayout>
  )
}
