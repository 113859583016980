import React, { FC } from 'react'

import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'
import { useTiktokCampaignDetails } from 'src/Redux/campaignTiktokDetailsApi/useTiktokCampaignDetails'
import { CampaignStatusEnum } from 'src/Types'
import { useTiktokCampaignVideos } from 'src/Redux/campaignVideosApi/useTiktokCampaignVideos'

import { ArtistRoutesEnum } from '../../../Router/ArtistComponentsNewUI'

import { TikTokDetailsBreadcrumbs } from './Components/TikTokDetailsBreadcrumbs'
import { Survey } from './Components/Survey'
import { SummarySection } from './Components/SummarySection'
import { ResultSection } from './Components/ResultSection'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 24px;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    padding-bottom: 56px;
    gap: 40px;
  }
`

const Content = styled.div`
  display: flex;
  max-width: 1120px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    flex-direction: column;
  }
`

export const TiktokCampaignDetailsPage: FC = () => {
  const navigate = useNavigate()

  const { campaignDetails, isLoading } = useTiktokCampaignDetails()
  const { campaignVideos, isLoading: isVideosLoading } =
    useTiktokCampaignVideos()

  const genresList = campaignDetails?.genres?.map((genre) => genre?.name || '')

  const onStartCampaignClick = (): void => {
    navigate(`${ArtistRoutesEnum.TiktokSchedule}/${campaignDetails?.id}`)
  }

  const credits = Math.max(
    (campaignDetails?.amount || 0) - (campaignDetails?.spent || 0),
    0,
  )

  return (
    <PageContainer>
      <Survey />
      <TikTokDetailsBreadcrumbs />
      <Content>
        <SummarySection
          campaignStatus={
            campaignDetails?.status as unknown as CampaignStatusEnum
          }
          loading={isLoading}
          spent={Number(campaignDetails?.spent)}
          trackCoverUrl={campaignDetails?.track?.coverUrl}
          trackName={campaignDetails?.track?.title}
          trackAuthorName={campaignDetails?.track?.authorName}
          startDate={campaignDetails?.startDate}
          endDate={campaignDetails?.endDate}
          genres={genresList}
          budget={campaignDetails?.amount?.toString()}
          reserved={campaignDetails?.reserved}
          credits={credits}
        />
        <ResultSection
          startDate={campaignDetails?.startDate}
          campaignStatus={
            campaignDetails?.status as unknown as CampaignStatusEnum
          }
          loading={isLoading || isVideosLoading}
          viewsCount={campaignDetails?.stats?.totalViews}
          commentsCount={campaignDetails?.stats?.totalComments}
          likesCount={campaignDetails?.stats?.totalLikes}
          videosCount={campaignDetails?.stats?.totalVideos}
          videos={campaignVideos?.videos}
          onStartCampaignClick={onStartCampaignClick}
        />
      </Content>
    </PageContainer>
  )
}
