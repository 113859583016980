import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { ChosenTrack } from 'src/Components/ChosenTrack'
import { useCreateNewCampaign } from 'src/Containers/SearchNewCampaignByPlatform/SelectedNewCampaign/useCreateNewCampaign'
import { onPlayAddedTrack } from 'src/Helpers/TagManager/TrackEvent'
import { useAppSelector } from 'src/Hooks/redux'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { getUserId } from 'src/Redux/auth-process/userSlice/selectors'
import { CampaignPlatformTypeEnum, CampaignTypeEnum } from 'src/Types'
import { ArtistRoutesEnum } from 'src/Router/ArtistComponentsNewUI'

import {
  SpotifyTrack,
  TikTokTrack,
} from '../SelectedTrackAuth/useSelectedTrackAuth'

export const SelectedNewTrack: FC<{
  tikTokTrack: TikTokTrack | null
  spotifyTrack: SpotifyTrack | null
  loading: boolean
}> = ({ tikTokTrack, spotifyTrack, loading: containerLoading }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const userId = useAppSelector(getUserId)
  const role = useAppSelector(getUserRole)
  const { platform } = useParams<{ platform: CampaignPlatformTypeEnum }>()
  const isTikTok = platform === CampaignPlatformTypeEnum.TIKTOK

  const { loading, createNewSpotifyCampaign, createNewTiktokCampaign } =
    useCreateNewCampaign()

  const handleCreate = (): void => {
    if (isTikTok) {
      tikTokTrack && createNewTiktokCampaign(tikTokTrack)
    } else {
      spotifyTrack && createNewSpotifyCampaign(spotifyTrack)
    }
  }

  const handleChangeTrack = (): void => {
    navigate(`${ArtistRoutesEnum.ChooseCreateCampaignPlatform}/${platform}`)
  }

  const track =
    platform === CampaignPlatformTypeEnum.TIKTOK ? tikTokTrack : spotifyTrack

  if (!track) {
    return null
  }

  const handleClick = (): void => {
    onPlayAddedTrack(
      CampaignTypeEnum.BASIC,
      role,
      platform ?? CampaignPlatformTypeEnum.SPOTIFY,
      userId,
    )
  }

  return (
    <ChosenTrack
      createLoading={loading}
      loading={containerLoading}
      artist={track?.artistName}
      title={
        isTikTok
          ? t('createNewCampaignPage.newTikTok')
          : t('createNewCampaignPage.newSpotify')
      }
      duration={track?.trackDuration}
      trackName={track?.trackName}
      trackUrl={track?.trackPreviewUrl}
      image={track?.trackCoverUrl}
      onChangeClick={handleChangeTrack}
      onCreateClick={handleCreate}
      onPlayEvent={handleClick}
    />
  )
}
