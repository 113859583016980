import { FC, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { Progress, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { useAppSelector } from 'src/Hooks/redux'
import {
  getCampaignToSchedule,
  getIsUpdateCampaignLoading,
} from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { HUNDRED, DIVIDE_BY_TWO } from 'src/Constants/numeric'
import { PrimaryHint } from 'src/Components/PrimaryHint'

const StyledPercentage = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  span {
    font-size: 12px;
    font-weight: 400;
  }
`
const Blocks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`
const MatchingBlock = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  height: 84px;
`
const SecondMatchingBlock = styled(MatchingBlock)`
  height: 141px;
`
const MatchingBlockHeaderPotentialReach = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MatchingBlockValueMain = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const CircularProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledSpin = styled(Spin)`
  margin: auto auto;
`

const STROKE_WIDTH = 7
export const SpotifySettingBudgetEstimation: FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const isUpdateCampaignLoading = useAppSelector(getIsUpdateCampaignLoading)

  const averageCuratorsCount = campaignDetails
    ? ((campaignDetails?.minCuratorsCount || 0) +
        (campaignDetails?.maxCuratorsCount || 0)) /
      DIVIDE_BY_TWO
    : 0

  const percentage =
    averageCuratorsCount > 0
      ? Math.min(
          (averageCuratorsCount / campaignDetails?.absoluteCuratorsCount) *
            HUNDRED,
          HUNDRED,
        )
      : 0

  const customFormat = (): ReactNode => (
    <StyledPercentage>
      {Math.round(percentage)}
      <span>%</span>
    </StyledPercentage>
  )
  return (
    <Blocks>
      <MatchingBlock>
        <MatchingBlockHeaderPotentialReach>
          <div>{t('scheduleCampaignPage.curatorsReach')}</div>
          <PrimaryHint>{t('scheduleCampaignPage.setReachHint')}</PrimaryHint>
        </MatchingBlockHeaderPotentialReach>
        {isUpdateCampaignLoading ? (
          <StyledSpin
            style={{ color: theme.common.secondaryBorder }}
            indicator={<LoadingOutlined spin />}
            size='large'
          />
        ) : (
          <MatchingBlockValueMain>
            {`${t('scheduleCampaignPage.upTo')} ${
              campaignDetails?.maxCuratorsCount ?? 0
            }`}
          </MatchingBlockValueMain>
        )}
      </MatchingBlock>
      <SecondMatchingBlock>
        <MatchingBlockHeaderPotentialReach>
          <div>{t('scheduleCampaignPage.campaignReach')}</div>
          <PrimaryHint>
            {t('scheduleCampaignPage.shareOfCuratorsMatchedWithYourTrack')}
          </PrimaryHint>
        </MatchingBlockHeaderPotentialReach>

        {isUpdateCampaignLoading ? (
          <StyledSpin
            style={{ color: theme.common.secondaryBorder }}
            indicator={<LoadingOutlined spin />}
            size='large'
          />
        ) : (
          <CircularProgressBarWrapper>
            <Progress
              type='circle'
              size={80}
              percent={percentage}
              strokeWidth={STROKE_WIDTH}
              trailColor={theme.common.primaryBorder}
              strokeColor={theme.common.secondaryBorder}
              format={customFormat}
            />
          </CircularProgressBarWrapper>
        )}
      </SecondMatchingBlock>
    </Blocks>
  )
}
