import React, { FC } from 'react'

import styled from 'styled-components'

import TrackCoverIcon from 'src/Assets/Svg/pages-icons/TrackCover.svg'

import { CampaignPlatformLabel } from '../CampaignPlatformLabel'
import { SmartImage } from '../SmartImage'
import { GreenLabel } from '../GreenLabel'

import { CampaignListItemProps } from '.'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`
const InfoBLock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`
const InfoBlockTrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

const PlatformAndMewReleaseContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const ArtistName = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
const TrackName = styled(ArtistName)`
  font-weight: 600;
`

const TrackImage = styled(SmartImage)`
  width: 68px;
  height: 68px;
  border-radius: 16px;
`

type CampaignTrackDetailsProps = Pick<
  CampaignListItemProps,
  'trackName' | 'artistName' | 'trackImage' | 'platform' | 'newRelease'
>

export const CampaignTrackDetails: FC<CampaignTrackDetailsProps> = ({
  platform,
  trackName,
  trackImage,
  artistName,
  newRelease,
}) => (
  <Container>
    <TrackImage src={trackImage} alt={trackName} fallbackSrc={TrackCoverIcon} />
    <InfoBLock>
      <InfoBlockTrackInfo>
        <TrackName>{trackName}</TrackName>
        <ArtistName>{artistName}</ArtistName>
      </InfoBlockTrackInfo>
      <PlatformAndMewReleaseContainer>
        <CampaignPlatformLabel platform={platform} />
        {newRelease && <GreenLabel text='New Release' />}
      </PlatformAndMewReleaseContainer>
    </InfoBLock>
  </Container>
)
