import { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import { colors } from 'src/Styled/variables'
import { campaignsList, details } from 'src/Router/routes'
import { TikTokCampaignPricingModelEnum } from 'src/Types'
import { getValueOrZero } from 'src/Constants/functions'
import { useCampaignVideo } from 'src/Redux/campaignVideoApi'

import { TiktokReviewHeader } from '../TiktokReviewHeader'
import { RateContainer } from '../RateContainer'
import { TiktokRateError } from '../TiktokRateError'
import { CreatorInfo } from '../CreatorInfo'
import { TikTokPlayer } from '../TiktokPlayer'
import { ViewsProgressInfo } from '../ViewsProgressInfo'
import { WontChargedAlerts } from '../WontChargedAlerts'

import {
  LoaderWrapper,
  TiktokReviewMobileWrapper,
  StyledBackground,
  CloseButton,
  RateWrapper,
  CreatorInfoWrapper,
} from './styles'

export const TiktokReviewMobile: FC = () => {
  const navigate = useNavigate()
  const {
    video,
    isLoading,
    isVideoDeclinedOrRejected,
    isVideoDeleted,
    pricingModel,
    campaignID,
  } = useCampaignVideo()

  const handleBack = (): void => {
    if (campaignID) {
      navigate(`${campaignsList}${details}/${campaignID}`)
    }
  }

  const isViewsHidden =
    pricingModel === TikTokCampaignPricingModelEnum.FollowersBased ||
    isVideoDeclinedOrRejected ||
    isVideoDeleted

  if (isLoading) {
    return (
      <LoaderWrapper>
        <ReactLoading
          color={colors.badgeGreen}
          width={'50px'}
          height={'50px'}
          type='spin'
          data-testid='loader'
        />
      </LoaderWrapper>
    )
  }
  return (
    <FullScreenModal showTopbar onCancel={handleBack} open prefixCls='genres'>
      <TiktokReviewMobileWrapper>
        <div>
          <TiktokReviewHeader />
          <CloseButton onClick={handleBack}>
            <CloseCross width='16px' height='16px' />
          </CloseButton>
        </div>
        <TiktokRateError />
        <WontChargedAlerts />
        <RateWrapper>
          <RateContainer />
        </RateWrapper>
        <StyledBackground>
          <TikTokPlayer url={video?.videoLink || ''} />
        </StyledBackground>
        <CreatorInfoWrapper>
          <CreatorInfo />
          {!isViewsHidden && (
            <ViewsProgressInfo
              currentViews={getValueOrZero(video?.viewsCount)}
              minViews={getValueOrZero(video?.minViews)}
              milestoneDate={video?.minViewsDeadline || ''}
            />
          )}
        </CreatorInfoWrapper>
      </TiktokReviewMobileWrapper>
    </FullScreenModal>
  )
}
