import styled from 'styled-components'

import { colors, MAX_MOBILE_WIDTH } from 'src/Styled/variables'

export const BalancePageContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: ${MAX_MOBILE_WIDTH}) {
    max-width: 1120px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    background-color: ${(props) => props.theme.common.primaryBg};
  }
`

export const PageSubTitle = styled.h2`
  font-size: 20px;
  line-height: 150%;
  font-weight: 600;
  color: ${colors.mainText};
  margin-top: 4px;
  margin-bottom: 6px;
`

export const Wrapper = styled.div`
  margin: 0 -16px;
`

export const InfoCardsWrapper = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  gap: 16px;
  @media (max-width: 1023px) {
    overflow-x: scroll;
  }
  width: 100%;
  padding: 16px;
  border-radius: 16px;
`

export const InfoCardWrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 16px 16px 16px 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    min-width: 228px;
    max-width: 228px;
  }

  width: 100%;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.common.primaryBorder};
  background-color: ${(props) => props.theme.common.primaryBg};
  box-shadow: 0px 0px 24px 0px rgba(51, 51, 51, 0.08);
`

export const PageContentContainer = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding: 24px;
  margin-bottom: 16px;
  position: relative;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.common.primaryBorder};
  background: ${(props) => props.theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-tab {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ant-tabs-tab-btn {
    color: ${colors.greyTextWhiteBg};
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.greyTextWhiteBg};
  }
  .ant-tabs-nav-list {
    border-bottom: 2px solid ${colors.greyStroke};
  }
  .ant-tabs-nav {
    height: 35px;
    margin-bottom: 24px;
    .ant-tabs-ink-bar {
      background: ${colors.green};
      height: 2px;
      border-radius: 1px;
      bottom: -1px;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
  .ant-table-column-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: ${colors.mainText};
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: ${colors.mainText};
    padding: 11px 24px !important;
    background: none !important;
    border-bottom: none !important;
    &:before {
      display: none;
    }
  }
  .ant-table-tbody .ant-table-cell {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: ${colors.mainText};
    padding: 24px !important;
    background: none !important;
    border-bottom: none !important;
    &:before {
      display: none;
    }
  }
  .ant-table-row {
    background: ${colors.greyBg};
    margin-bottom: 2px;
    min-height: 78px;
  }
  .ant-table-content table {
    border-spacing: 0 2px;
  }
  .ant-pagination {
    display: none;
  }
`
// Table styled

export const HeaderTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${colors.mainText};
`

export const Amount = styled.span<{ isRed?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ isRed }) => (isRed ? colors.badgeRed : colors.mainText)};
`
export const TrackCard = styled.span`
  display: flex;
  flex-direction: row;
`
export const TrackCardInfoWrapper = styled.span`
  display: flex;
  flex-direction: column;
`
export const IconWrapper = styled.div`
  display: flex;
  margin-top: 3px;
  margin-right: 8px;
  svg {
    width: 16px;
    height: 16px;
  }
`
export const InfoText = styled.span<{ isRed?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ isRed }) => (isRed ? colors.badgeRed : colors.greyTextWhiteBg)};
`
export const InfoTitle = styled.span<{ isRed?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ isRed }) => (isRed ? colors.badgeRed : colors.mainText)};
`

export const MobileInfoText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${colors.greyTextWhiteBg};
  margin-top: 20px;
  text-align: center;
`
export const AmountValue = styled.span`
  margin-left: 10px;
`
export const PrefixValue = styled.span`
  position: absolute;
  left: 0;
  top: -2px;
`
export const AmountWrapper = styled.span<{ hiddenPrefix?: boolean }>`
  position: relative;
`

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;

  @media screen and (max-width: 576px) {
    padding-left: 0;
    padding-right: 0;
  }
`
