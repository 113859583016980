import { FC } from 'react'

import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { CampaignPlatformTypeEnum, CampaignStatusEnum } from 'src/Types'
import { MAX_TABLET_WIDTH, MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { CampaignTrackDetails } from './CampaignTrackDetails'
import { CampaignListItemStatus } from './CampaignListItemStatus'
import { CampaignListItemButtons } from './CampaignListItemButtons'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  background: ${({ theme }) => theme.common.primaryBg};
  box-sizing: border-box;
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`

export interface CampaignListItemProps {
  className?: string
  trackName?: string
  newRelease?: boolean
  artistName?: string
  trackImage?: string
  status: CampaignStatusEnum
  platform: CampaignPlatformTypeEnum
  statusText?: string
  startLoading?: boolean
  relaunched?: boolean
  relaunchLoading?: boolean
  onDetailsClick?: () => void
  onStartCampaignClick?: () => void
  onRelaunchCampaignClick?: () => void
}

const TabletContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const CampaignListItem: FC<CampaignListItemProps> = (props) => {
  const {
    className,
    trackImage,
    trackName,
    artistName,
    platform,
    startLoading,
    relaunchLoading,
    status,
    statusText,
    newRelease,
    relaunched,
    onDetailsClick,
    onRelaunchCampaignClick,
    onStartCampaignClick,
  } = props
  const isTablet = useMediaQuery({
    minWidth: MAX_MOBILE_WIDTH,
    maxWidth: MAX_TABLET_WIDTH,
  })

  if (isTablet) {
    return (
      <Container className={className}>
        <TabletContainer>
          <CampaignTrackDetails
            trackImage={trackImage}
            trackName={trackName}
            artistName={artistName}
            platform={platform}
            newRelease={newRelease}
          />
          <CampaignListItemStatus status={status} statusText={statusText} />
        </TabletContainer>
        <CampaignListItemButtons
          relaunched={relaunched}
          startLoading={startLoading}
          relaunchLoading={relaunchLoading}
          newRelease={newRelease}
          platform={platform}
          status={status}
          onDetailsClick={onDetailsClick}
          onRelaunchCampaignClick={onRelaunchCampaignClick}
          onStartCampaignClick={onStartCampaignClick}
        />
      </Container>
    )
  }
  return (
    <Container className={className}>
      <CampaignTrackDetails
        trackImage={trackImage}
        trackName={trackName}
        artistName={artistName}
        platform={platform}
        newRelease={newRelease}
      />
      <CampaignListItemStatus status={status} statusText={statusText} />
      <CampaignListItemButtons
        startLoading={startLoading}
        relaunched={relaunched}
        relaunchLoading={relaunchLoading}
        newRelease={newRelease}
        platform={platform}
        status={status}
        onDetailsClick={onDetailsClick}
        onRelaunchCampaignClick={onRelaunchCampaignClick}
        onStartCampaignClick={onStartCampaignClick}
      />
    </Container>
  )
}
