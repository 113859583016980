import React, { FC } from 'react'

import { NewReleaseResponse } from 'src/Redux/newReleaseApi'
import { UserNotification } from 'src/Types'
import avatar from 'src/Assets/Png/ava.png'
import { SmartImage } from 'src/Components/SmartImage'
import { OtherNotification } from 'src/Containers/Layout/Topbar/TopbarNotification/OtherNotification'

import { ArtistNotification } from '../ArtistNotification'
import { TopBarNewRelease } from '../TopBarNewRelease'
import { NotificationsItemCurator, ImageCurator } from '../../styles'

interface NotificationItemProps {
  notification: UserNotification
  handleCreateAndStartSchedule?: () => void
  releaseData?: NewReleaseResponse
}

export const NotificationItem: FC<NotificationItemProps> = (props) => {
  const { notification, handleCreateAndStartSchedule, releaseData } = props

  if (!notification) {
    return null
  }
  if (notification.isRelease) {
    return (
      <TopBarNewRelease
        key={notification.id}
        handleCreateAndStartSchedule={handleCreateAndStartSchedule}
        releaseData={releaseData}
      />
    )
  }
  if ('extra_score' in notification) {
    return (
      <NotificationsItemCurator key={notification.id}>
        <ImageCurator>
          <SmartImage src={avatar} alt='avatar' fallbackSrc={avatar} />
        </ImageCurator>
        <OtherNotification {...notification} />
      </NotificationsItemCurator>
    )
  }
  return (
    <ArtistNotification
      key={notification.id}
      firstName={notification?.Playlist?.Curator?.first_name || ''}
      lastName={notification?.Playlist?.Curator?.last_name || ''}
      photo={notification?.Playlist?.Curator?.user_photo_url || ''}
      date={notification?.date}
      playlistName={notification?.Playlist?.playlist_name || ''}
      playlistUrl={notification?.Playlist?.playlist_url || ''}
    />
  )
}
