import { FC } from 'react'

import styled from 'styled-components'

import { variables } from 'src/Styled/variables'

export interface GreenLabelProps {
  text: string
}

const StyledGreenLabel = styled.div`
  font-family: 'Segoe UI';
  font-size: ${variables.fontSize12};
  font-style: normal;
  font-weight: ${variables.fontWeight400};
  line-height: 150%;
  color: ${({ theme }) => theme.common.secondaryBorder};
  border-radius: 16px;
  background: ${({ theme }) => theme.common.fourthBg};
  padding: 3px 4px;
  min-width: 80px;
  text-align: center;
`

export const GreenLabel: FC<GreenLabelProps> = ({ text }) => (
  <StyledGreenLabel>{text}</StyledGreenLabel>
)
